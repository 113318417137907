import React, {Component} from 'react';

import Header from '../../Layout/Header';
import Footer from '../../Layout/Footer';
import InnerPageBanner from '../../Layout/InnerPageBanner';
import Breadcrumb from '../../Layout/Breadcrumb';

class TechnicalCatalogue extends Component{

    componentDidMount() {
        document.body.style.background = '#ffffff';
    }


    render(){
        return(
            <div>
            <div className="header" id="home">
                <Header />          
            </div>
            <div className="container_fluid main_padd">
            <div className="container">

                <h3 className="main_heading" style={{textTransform: 'uppercase'}}>Technical catalogues</h3>
                <h5 className="heading_h5">
</h5>
                <div className="row products_box_02">
                     
                     <div className="col-md-6">
                        <a  href={process.env.PUBLIC_URL + "/assets/pdf/MKT09122021_ELCO_Heating_Catalogue_ENG.pdf"} target="_blank">
                              <h4 style={{textAlign: 'left'}}>HEATING CATALOGUE</h4>
                              <p style={{textAlign: 'left'}}>Monoblock burners from 11 kW to 2300 kW for residential and commercial heating applications</p>
                              <br />
                             <img src={process.env.PUBLIC_URL + "/assets/images/pdimag.jpg"} className="img-responsive" style={{margin:'0px'}} />
                             
                       
                        </a>
                     </div>
                     
                       <div className="col-md-6">
                        <a  href={process.env.PUBLIC_URL + "/assets/pdf/MKT09122021_ELCO_Industry_Catalogue_ENG.pdf"} target="_blank">
                              <h4 style={{textAlign: 'left'}}>INDUSTRY CATALOGUE</h4>
                              <p style={{textAlign: 'left'}}>Monoblock and duoblock burners from 2 MW to 80 MW for industrial applications</p>
                              <br />
                             <img src={process.env.PUBLIC_URL + "/assets/images/pdimag2.jpg"} className="img-responsive" style={{margin:'0px'}} />
                             
                        </a>
                     </div>                     
                </div>
                
       
            </div>
            {/* <div className="container">
        
             
                <div className="row products_box_02">
                  
                
                    
                      <div className="col-md-6">
                        <a  href={process.env.PUBLIC_URL + "/assets/pdf/MEDENUS catalogue 2018_2019.pdf"} target="_blank">
                              <h4 style={{textAlign: 'left'}}>Catalogue</h4>
                             
                              <br />
                             <img src={process.env.PUBLIC_URL + "/assets/images/catloga1.jpg"} className="img-responsive" style={{margin:'0px',border:'1px #000 solid'}} />
                             
                       
                        </a>
                     </div>
                     
                     
                  
                     
                </div>
                
       
            </div> */}
        </div>
				
            
                <Footer />

            </div>
        )
    }

}


export default TechnicalCatalogue;