import React, {Component} from 'react';

class Header extends Component{

    render(){
        return(
            
            <div className="top-bar_w3agileits">
                {/* <div className="top-logo_info_w3layouts ">
                    <div className="col-md-3 logo">
            
                        <a className="navbar-brand" href="index"><img src={process.env.PUBLIC_URL + "/assets/images/logo.png"} className="img-responsive" /></a>
            
                    </div>
                    <div className="col-md-9 adrress_top">
                        <div className="adrees_info">
                            <div className="col-md-5 visit">
                                <div className="col-md-2 col-sm-2 col-xs-2 contact-icon">
                                    <span className="fa fa-envelope" aria-hidden="true"></span>
                                </div>
                                <div className="col-md-10 col-sm-10 col-xs-10 contact-text">
                                    <h4>E-mail</h4>
                                    <p><a href="mailto:alok.dev@euromaxglobal.com">alok.dev@euromaxglobal.com</a></p>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                            <div className="col-md-5 mail-us">
                                <div className="col-md-2 col-sm-2 col-xs-2 contact-icon">
                                    <span className="fa fa-mobile" aria-hidden="true" style={{fontSize: '30px'}}></span>
                                </div>
                                <div className="col-md-10 col-sm-10 col-xs-10 contact-text">
                                    <h4>Contact No.</h4>
                                    <p><a href="tel:+91-9711139301">+91-9711139301</a></p>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                        <ul className="col-md-2 top-right-info_w3ls">
                            <div id="google_translate_element" className="main_goo"></div>
        
                        </ul>
                        <div className="clearfix"></div>
                    </div>
                    <div className="clearfix"></div>
                </div> */}
                <div className="header-nav">
                
                    <div className="inner-nav_wthree_agileits">
                    <div className="col-md-3 logo">
                     <a className="navbar-brand" href="index">
                        <img src={process.env.PUBLIC_URL + "/assets/images/logo.png"}/></a>
                </div>
                        <nav className="navbar navbar-default">
                            
                            <div className="navbar-header">
                                <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
                                    <span className="sr-only">Toggle navigation</span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                            </div>
                            
                            <div className="collapse navbar-collapse nav-wil" id="bs-example-navbar-collapse-1">
                                <nav>
                                    <ul className="nav navbar-nav">
                                        <li><a href="/">Home</a></li>
                                        <li><a href="/about-us">About Us</a></li>
                                        <li className="dropdown">
                                            <a href="#" className="dropdown-toggle" data-toggle="dropdown">Products <b className="caret"></b></a>
                                            <ul className="dropdown-menu">
                                                <li><a href="/Industrial-Burners">Industrial Burners- elco Product</a></li>
                                                <li><a href="/Gas-Pressure-Regulators">Gas Train- Medenus Product</a></li>
                                                <li><a href="/">Gas Component- elektrogas</a></li>
                                            </ul>
                                        </li>  
                                        <li className="dropdown">
                                            <a href="#" className="dropdown-toggle" data-toggle="dropdown">Download <b className="caret"></b></a>
                                            <ul className="dropdown-menu">
                                                <li><a href="/technical-catalogues">Technical Catalogues</a></li>
                                                <li><a href="/commercial-documentation">Marketing Catalogues</a></li>  
                                                <li><a target="_blank" href="/assets/pdf/MEDENUS Catalogue 2020 EN.pdf">Medenus Catalogues</a></li>   
                                                <li><a href="/">elektrogas Catalogues</a></li>           
                                            </ul>
                                        </li>
                                        {/*                                       
                                        <li className="dropdown">
                                            <a href="#" className="dropdown-toggle" data-toggle="dropdown">Customer Care <b className="caret"></b></a>
                                            <ul className="dropdown-menu">
                                                <li><a href="service">Service</a></li>        
                                            </ul>
                                        </li> */}
                                        <li><a href="contact-us">Contact Us</a></li>
                                       {/*  <li><a href="enquiry">Enquiry</a></li> */}
                                    </ul>
                                </nav>
            
                            </div>
                        </nav>
                        <div className="search">
                                                
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        )
    }

}


export default Header;