import React, {Component} from 'react';

import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import InnerPageBanner from '../Layout/InnerPageBanner';
import Breadcrumb from '../Layout/Breadcrumb';
import {Link, withRouter} from "react-router-dom"; // You will need these imports.
class GasPressureRegulators extends Component{

	constructor(props) {
		super(props);
		this.state= {      
		//  comments: AppStore.getAll();
		};
	  }

	navigateTo = (pageName) => {
		var navigatePath = this.props.match.path + '/' +pageName;
		this.props.history.push(navigatePath);
	}


    componentDidMount() {
        document.body.style.background = '#ffffff';
    }


    render(){
		
        return(
            <div>

            <div className="header" id="home">
                
                <Header />        
                    
                    
            </div>
            		 
						 
				<div class="container_fluid main_padd">
					<div class="container">
						
						<h3 class="main_heading" styles={{textTransform: 'uppercase'}}>Gas Pressure Regulators</h3>
						
						<div class="row products_box_02">
							
							<div class="col-md-3">
								<a href="javascript:void(0)" onClick={this.navigateTo.bind(this,'medenus-r50')}>
									<img src={process.env.PUBLIC_URL + "/assets/images/pro_11.jpg"} class="img-responsive" />
								<h4>R50</h4>
								<p>threaded gas pressure regulator</p>
								</a>
							</div>
							
							<div class="col-md-3">
								<a href="javascript:void(0)" onClick={this.navigateTo.bind(this,'medenus-r51')}>
									<img src={process.env.PUBLIC_URL + "/assets/images/pro_12.jpg"} class="img-responsive" />
								<h4>R51 </h4>
								<p>Gas pressure regulator</p>
								</a>
							</div>
							
							<div class="col-md-3">
								<a href="javascript:void(0)" onClick={this.navigateTo.bind(this,'medenus-r100')}>
									<img src={process.env.PUBLIC_URL + "/assets/images/pro_13.jpg"} class="img-responsive" />
								<h4>R100 </h4>
								<p>Gas Pressure Regulator</p>
								</a>
							</div>
							
							<div class="col-md-3">
								<a href="javascript:void(0)" onClick={this.navigateTo.bind(this,'medenus-r100ud')}>
									<img src={process.env.PUBLIC_URL + "/assets/images/pro_14.jpg"} class="img-responsive" />
								<h4>R100UD</h4>
								<p>Vacuum Regulator</p>
								</a>
							</div>
							
							
							
						</div>
						
						<div class="row products_box_02">
							
							<div class="col-md-3">
								<a href="javascript:void(0)" onClick={this.navigateTo.bind(this,'medenus-r101')}>
									<img src={process.env.PUBLIC_URL + "/assets/images/pro_16.jpg"} class="img-responsive" />
								<h4>R101</h4>
								<p>Gas pressure regulator</p>
								</a>
							</div>
							
							<div class="col-md-3">
								<a href="javascript:void(0)" onClick={this.navigateTo.bind(this,'medenus-rs250')}>
									<img src={process.env.PUBLIC_URL + "/assets/images/pro_18.jpg"} class="img-responsive" />
								<h4>RS250</h4>
								<p>Gas pressure regulator with built in safety shut-off valve</p>
								</a>
							</div>
							
							<div class="col-md-3">
								<a href="javascript:void(0)" onClick={this.navigateTo.bind(this,'medenus-rs251')}>
									<img src={process.env.PUBLIC_URL + "/assets/images/pro_19.jpg"} class="img-responsive" />
								<h4>RS251</h4>
								<p>Gas pressure regulator with built in safety shut-off valve</p>
								</a>
							</div>
							
							<div class="col-md-3">
								<a href="javascript:void(0)" onClick={this.navigateTo.bind(this,'medenus-rs254')}>
									<img src={process.env.PUBLIC_URL + "/assets/images/pro_20.jpg"} class="img-responsive" />
								<h4>RS254</h4>
								<p>Gas pressure regulator with built in safety shut-off valve</p>
								</a>
							</div>
							
						</div>
						
						<div class="row products_box_02">
							
							<div class="col-md-3">
								<a href="javascript:void(0)" onClick={this.navigateTo.bind(this,'medenus-rs255')}>
									<img src={process.env.PUBLIC_URL + "/assets/images/pro_21.jpg"} class="img-responsive" />
									<h4>RS255</h4>
									<p>Gas pressure regulator with built in safety shut-off valve</p>
								</a>
							</div>
							
							<div class="col-md-3">
								<a href="javascript:void(0)" onClick={this.navigateTo.bind(this,'medenus-rsp254')}>
									<img src={process.env.PUBLIC_URL + "/assets/images/pro_22.jpg"} class="img-responsive" />
								<h4>RSP254</h4>
								<p>pilot operated regulator</p>
								</a>
							</div>
							
						</div>
						
						<div class="row products_box_02">
							
							<div class="col-md-3">
								<a href="javascript:void(0)" onClick={this.navigateTo.bind(this,'medenus-rsp255')}>
									<img src={process.env.PUBLIC_URL + "/assets/images/pro_24.jpg"} class="img-responsive" />
								<h4>RSP255</h4>
								<p>pilot operated regulator</p>
								</a>
							</div>
							
						</div>
						<div class="row products_box_02">
						<h3 class="main_heading" styles={{textTransform: 'uppercase',margin:'0px',padding:'0px'}}>
							Safety relief valves
						</h3> 
							
							
							<div class="col-md-3">
								<a href="javascript:void(0)" onClick={this.navigateTo.bind(this,'medenus_sl_10')}>
									<img src={process.env.PUBLIC_URL + "/assets/images/PRO25.jpg"} class="img-responsive" />
								<h4>SL10</h4>
								<p>The safety relief valve SL10 </p>
								</a>
							</div>
						</div>
						
						
					<div class="row products_box_02">
							<h3 class="main_heading" styles={{textTransform: 'uppercase',margin:'0px',padding:'0px'}}>
						Safety shut-off valve
						</h3> 
							<div class="col-md-3">
								<a href="javascript:void(0)" onClick={this.navigateTo.bind(this,'medenus_s50')}>
									<img src={process.env.PUBLIC_URL + "/assets/images/pro26.jpg"} class="img-responsive" />
								<h4>S50</h4>
								<p>The S50 Safety Shut Off valve</p>
								</a>
							</div>
							
							
							<div class="col-md-3">
								<a href="javascript:void(0)" onClick={this.navigateTo.bind(this,'medenus_s100')}>
									<img src={process.env.PUBLIC_URL + "/assets/images/pro27.jpg"} class="img-responsive" />
								<h4>S100</h4>
								<p>The S100 Safety Shut Off valve</p>
								</a>
							</div>
							
							
						</div>
						
						<div class="row products_box_02">
							<h3 class="main_heading" styles={{textTransform: 'uppercase'}}>Cellular Gas Filter</h3>
							
							<div class="col-md-3">
								<a href="javascript:void(0)" onClick={this.navigateTo.bind(this,'MEDENUS_CELLULAR_GAS_FILTER_DF_100')}>
									<img src={process.env.PUBLIC_URL + "/assets/images/pro28.jpg"} class="img-responsive" />
								<h4>Cellular Gas Filter DF100</h4>
								<p>The new MEDENUS cellular gasfilter series DF100 </p>
								</a>
							</div>
							
							
						</div>
						
						<div class="row products_box_02">
							<h3 class="main_heading" styles={{textTransform: 'uppercase'}}>Turbine meters</h3>
							
							<div class="col-md-3">
								<a href="javascript:void(0)" onClick={this.navigateTo.bind(this,'medenus_turbine_meters_quantometer')}>
									<img src="images/pro30.jpg" src={process.env.PUBLIC_URL + "/assets/images/pro30.jpg"} class="img-responsive" />
								<h4>Turbine meters (quantometer)</h4>
								<p>The turbine meters (quantometer) </p>
							
								</a>
							</div>
							<div class="col-md-3">
								<a href="javascript:void(0)" onClick={this.navigateTo.bind(this,'ELECTRONIC_TURBINE_METER_MQME_QUANTOMETER')}>
									<img src={process.env.PUBLIC_URL + "/assets/images/pro29.jpg"} class="img-responsive" />
								<h4> Electronic Turbine Metter Mqme - Quantometer</h4>
								<p>The MQMe Quantometer is a turbine</p>
							
								</a>
							</div>
							
							
						</div>
						
						
						
						<div class="row products_box_02">
							
							<h3 class="main_heading" styles={{textTransform: 'uppercase'}}>Gas Ball Valves</h3>
							
							
							<div class="col-md-3">
								<a href="javascript:void(0)" onClick={this.navigateTo.bind(this,'MEDENUS_gas_ball_valve')}>
									<img src={process.env.PUBLIC_URL + "/assets/images/pro31.jpg"} class="img-responsive" />
								<h4>Gas Ball Valve</h4>
								<p>Gas ball valves are  mainly used  </p>
							
								</a>
							</div>
							
						</div>
						
						
						
					</div>
				</div>
        
        
        
				
                
            <Footer />

            </div>
        )
    }

}


export default withRouter(GasPressureRegulators);