import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom"; // You will need these imports.
import Header from '../../Layout/Header';
import Footer from '../../Layout/Footer';
import InnerPageBanner from '../../Layout/InnerPageBanner';
import Breadcrumb from '../../Layout/Breadcrumb';
import {jsonData} from './jsonData';

import ReactHtmlParser from "react-html-parser";

var pageName = '';
class GasPressureRegulatorsFile extends Component{

	constructor(props) {
		super(props);
		this.state= {      
			pageName: '', 
            mainHeading: '', 
            secondryHeading:'', 
            contentFirst: '', 
            contentSecond: '', 
            listItem:'', 
            image:'',
            pdfFileLink:''
		};
	  }

    componentDidMount() {
        document.body.style.background = '#ffffff';
		console.log(this.props.match.params.id);
		pageName = this.props.match.params.id;

		const displayData = jsonData.find(el => el.pageName === pageName); // Possibly returns `undefined`
		console.log(displayData);

		this.setState({
			pageName: displayData.pageName, 
            mainHeading: displayData.mainHeading, 
            secondryHeading: displayData.secondryHeading, 
            contentFirst: displayData.contentFirst, 
            contentSecond: displayData.contentSecond, 
            image: displayData.image,
            listItem: displayData.listItem,
            pdfFileLink: displayData.pdfFileLink
		});
    }

    render(){
		
        return(
            <div>

            <div className="header" id="home">
                
                <Header />           
                    
            </div>
            <Breadcrumb Breadcrumb={this.state.pageName} />

			<div className="container_fluid main_padd">
            <div className="container">
				
                <h3 className="main_heading">{ReactHtmlParser(this.state.mainHeading)}</h3>
                <div className="row products_box_03">
                    <div className="col-md-6">
                        <h4 className="products_box_03_h4">{ReactHtmlParser(this.state.secondryHeading)}</h4>
                        {ReactHtmlParser(this.state.contentFirst)}
						{ReactHtmlParser(this.state.contentSecond)}
                        
						<ul className="faq faq_main">
						{
							this.state.listItem.length > 0 && this.state.listItem.map(element => (
								<li className="item1 wow fadeInDown animated" data-wow-delay=".5s"><a href="#"><span className="glyphicon glyphicon-chevron-down"></span>  {element.title}</a>
                                <ul>
                                    <li className="subitem1">
                                        <p>{element.description}</p>
                                    </li>
                                </ul>
                            </li>
							))
						}
					        
                        </ul>
                        
                        <div className="contact_button"> <a href="../contact-us">Contact Us</a> </div>
                    </div>
                    <div className="col-md-6"> <img src={process.env.PUBLIC_URL + "/assets/images/"+this.state.image} className="img-responsive" /> </div>
                </div>
            </div>
        </div>
	
            <Footer />

            </div>
        )
    }

}


export default withRouter(GasPressureRegulatorsFile);