export const jsonData = [
        { 
            pageName: 'medenus-r50',
            mainHeading: 'MEDENUS R50',
            secondryHeading:'<h4>MEDENUS GAS PRESSURE REGULATOR R50</h4>', 
            contentFirst:'<p className="products_box_03_p1">The MEDENUS gas pressure regulator type R50 ensures a constant outlet pressure when the gas usage varies and ensures a quick pressure regulation when the flow rate abruptly changes.</p><p className="products_box_03_p2">The gas pressure regulator R50 is suitable for gases in accordance with DVGW Code of Practice G260/262 and all other neutral and non-corrosive gases (others upon request).The R50 is available with thread connections Rp 1", Rp 1,5", Rp 2" and a maximum inlet pressure up to 3,0 bar. All gas pressure regulators are certified by DVGW and CE.</p>',
            contentSecond:'',
            listItem:[
                {
                    title:'Application',
                    description:'Usable up to 3.0 bar maximum inlet pressure for all gases in accordance with DVGW Code of Practice G260/G262 and all non-corrosive gases (others upon request); outlet pressure: maximum 1.2 bar; temperature range: -20°C to + 60°C'   
                },
                {
                    title:'Characteristics',
                    description:'spring loaded single seated valve with gas tight zero position; body material: alluminium cast alloy'   
                },
                {
                    title:'Connections',
                    description:'Rp 1", Rp 1 ½", Rp 2'   
                }
            ],
            image:'pro_11.jpg',
            pdfFileLink:''
        },
        { 
            pageName: 'medenus-r51',
            mainHeading: 'MEDENUS R51',
            secondryHeading:'<h4>MEDENUS GASDRUCKREGLER R51</h4>', 
            contentFirst:'<p class="products_box_03_p1">The gas pressure regulator R51 is suitable for gases in accordance with DVGW Code of Practice G260/262 and all other neutral and non-corrosive gases (others upon request).The gas pressure regulator R51 is available only with flange connections DN25, a maximum inlet pressure of 10 bar and a maximum outlet pressure of 3 bar. All gas pressure regulators are certified by DVGW and CE.</p>',
            contentSecond:'',
            listItem:[
                {
                    title:'Application',
                    description:'Usable up to 10 bar maximum inlet pressure for all gases in accordance with DVGW Code of Practice G260/G262 and all non-corrosive gases (others upon request); outlet pressure: maximum 3 bar; temperature range: -20°C to + 60°C'
                },
                {
                    title:'Versions',
                    description:'Available as gas pressure regulator R51 without integrated safety shut off valve for smaller flow rates up to a maximum KG value of 175.'
                },
                {
                    title:'Characteristics',
                    description:'spring loaded single seated valve with gas tight zero position; body material: alluminium cast alloy'
                },
                {
                    title:'Flange connections',
                    description:'PN16 for DN25 according to DIN EN 1092-4'
                }
            ],
            image:'pro_12.jpg',
            pdfFileLink:''
        },
        { 
            pageName: 'medenus-r100',
            mainHeading: 'MEDENUS R100',
            secondryHeading:'<h4>MEDENUS GAS PRESSURE REGULATOR R100</h4>', 
            contentFirst:'<p className="products_box_03_p1">The MEDENUS gas pressure regulator type R100 ensures a constant outlet pressure - regardless of the primary (inlet) pressure - when the gas usage varies and ensures a quick pressure regulation when the flow rate abruptly changes. The gas pressure regulator R100 is also availbale as a rotary regulator R100U and as a vaccum ragulator R100UD (especially for biogas plants and gas engines)</p><p className="products_box_03_p2">The gas pressure regulator R100 is suitable for gases in accordance with DVGW Code of Practice G260/262 and all other neutral and non-corrosive gases (others upon request).The R100 is available with flange connections DN50 to DN200 and working pressure up to 8,0 bar. All gas pressure regulators are certified by DVGW and CE.</p>',
            contentSecond:'',
            listItem:[
                {
                    title:'Application',
                    description:'Usable up to 8.0 bar maximum inlet pressure for all gases in accordance with DVGW Code of Practice G260/G262 and all non-corrosive gases (others upon request); outlet pressure: maximum 1.2 bar; temperature range: -20°C to + 60°C'
                },
                {
                    title:'Versions',
                    description:'Available as gas pressure regulator R100, rotary regulator R100U or vacuum regulator R100UD'
                },
                {
                    title:'Characteristics',
                    description:'spring loaded double seated valve with gas tight zero position; body material: alluminium cast alloy'
                },
                {
                    title:'Flange connections',
                    description:'PN16 for DN50, DN80, DN100, DN150 according to DIN EN 1092-4; PN10 for DN200 according to DIN EN 1092-4'
                }
            ],
            image:'pro_12.jpg',
            pdfFileLink:''
        },
        { 
            pageName: 'medenus-r100ud',
            mainHeading: 'MEDENUS R100UD',
            secondryHeading:'<h4>MEDENUS R100UD</h4>', 
            contentFirst:'<p className="products_box_03_p1">The gas pressure regulator R100 is also available as a vacuum regulator (e.g. for gas engines) R100UD. For further information please get in contact with our sales department.</p>',
            contentSecond:'',
            listItem:[],
            image:'pro_14.jpg',
            pdfFileLink:''
        },
        { 
            pageName: 'medenus-r101',
            mainHeading: 'MEDENUS R101',
            secondryHeading:'<h4>MEDENUS GAS PRESSURE REGULATOR R101</h4>', 
            contentFirst:'<p className="products_box_03_p1">The MEDENUS gas pressure regulator type R101 ensures a constant outlet pressure - regardless of the primary (inlet) pressure - when the gas usage varies and ensures a quick pressure regulation when the flow rate abruptly changes. The gas pressure regulator R101 is also available as a Pressure relief valve. (e.g. for gas torches)</p><p className="products_box_03_p2">The gas pressure regulator R101 is suitable for gases in accordance with DVGW Code of Practice G260/262 and all other neutral and non-corrosive gases (others upon request).The R101 is available with flange connections DN25 to DN100 and working pressure up to 8,0 bar. All gas pressure regulators are certified by DVGW and CE.</p>',
            contentSecond:'',
            listItem:[
                {
                    title:'Application',
                    description:'Usable up to 8.0 bar maximum inlet pressure for all gases in accordance with DVGW Code of Practice G260/G262 and all non-corrosive gases (others upon request); outlet pressure: maximum 1.2 bar; temperature range: -20°C to + 60°C'
                },
                {
                    title:'Versions',
                    description:'Available as gas pressure regulator R101 or as a relief pressure valve for very small opening pressures'
                },
                {
                    title:'Characteristics',
                    description:'spring loaded single seated valve with gas tight zero position and primary pressure equalisation; body material: alluminium cast allo'
                },
                {
                    title:'Flange connections',
                    description:'PN 16 für DN25, DN40, DN50, DN65, DN100 nach DIN EN 1092-4'
                }
            ],
            image:'pro_16.jpg',
            pdfFileLink:''
        },
        { 
            pageName: 'medenus-rs250',
            mainHeading: 'MEDENUS RS250',
            secondryHeading:'<h4>MEDENUS RS250</h4>', 
            contentFirst:'<p className="products_box_03_p1">The MEDENUS gas pressure regulator type RS250 ensures a constant outlet pressure - regardless of the primary (inlet) pressure - when the gas usage varies and ensures a quick pressure regulation when the flow rate abruptly changes. The inbuilt safety shut off valve ensures a safe shut off if the pressure is too high or too low.</p>',
            contentSecond:'',
            listItem:[
                {
                    title:'Application',
                    description:'Usable up to 8.0 bar maximum inlet pressure for all gases in accordance with DVGW Code of Practice G260/G262 and all non-corrosive gases (others upon request); outlet pressure: maximum 1.2 bar; temperature range: -20°C to + 60°C'
                },
                {
                    title:'Versions',
                    description:'spring loaded single seated valve with integrated safety shut off valve, gas tight zero position and primary pressure equalisation; body material: alluminium cast alloy'
                },
                {
                    title:'Flange connections',
                    description:'PN16 for DN25, DN50, DN80, DN100, DN150 according to DIN EN 1092-4; according to ASME-B16.5-Class150RF'
                },
                {
                    title:'Integrated Shut off Value',
                    description:'Release: lack of pressure: 5 to 300 mbar; Overpressure: 30 to 1.500 mbar; Remote indication as an option available'
                },
                {
                    title:'Options',
                    description:'safety membrane or integrated safety relief valve, vent valve, noise reduction, nozzle valve for impulse connection, coatings in RAL colours and a special version for oxygen applications.'
                }
                ,
                {
                    title:'options',
                    description:'manual or remote relase of SSV, remote indication SSV „closed“ via induktive sensor or reed contact'
                }
            ],
            image:'pro_18.jpg',
            pdfFileLink:''
        },
        { 
            pageName: 'medenus-rs251',
            mainHeading: 'MEDENUS RS251',
            secondryHeading:'<h4>MEDENUS GAS PRESSURE REGULATOR RS251</h4>', 
            contentFirst:'<p className="products_box_03_p1">The MEDENUS gas pressure regulator type RS251 ensures a constant outlet pressure - regardless of the primary (inlet) pressure - when the gas usage varies and ensures a quick pressure regulation when the flow rate abruptly changes. The inbuilt safety shut off valve ensures a safe shut off if the pressure is too high or too low.</p><p className="products_box_03_p2">The gas pressure regulator RS251 is suitable for gases in accordance with DVGW Code of Practice G260/262 and all other neutral and non-corrosive gases (others upon request).The RS251 is available with flange connections DN25 to DN200 and working pressure up to 8,0 bar. All gas pressure regulators are certified by DVGW and CE.</p><p className="products_box_03_p2"> All regulators of the RS-series are with options like safety membrane, vent valve, remote release of SSV, noise reduction and many more available. For any details please look into our documentation or get in contact with us.</p>',
            contentSecond:'',
            listItem:[
                {
                    title:'Application',
                    description:'Usable up to 8.0 bar maximum inlet pressure for all gases in accordance with DVGW Code of Practice G260/G262 and all non-corrosive gases (others upon request); outlet pressure: maximum 1.2 bar; temperature range: -20°C to + 60°C'
                },
                {
                    title:'Options',
                    description:'safety membrane or integrated safety relief valve, vent valve, noise reduction, nozzle valve for impulse connection, coatings in RAL colours and a special version for oxygen applications.'
                },
                {
                    title:'Characteristics',
                    description:'spring loaded single seated valve with integrated safety shut off valve, gas tight zero position and primary pressure equalisation; body material: alluminium cast alloy'
                },
                {
                    title:'Flange connections',
                    description:'PN16 for DN50, DN80, DN100 according to DIN EN 1092-4; according to ASME-B16.5-Class150RF'
                },
                {
                    title:'Integrated Shut off Value',
                    description:'Release: lack of pressure: 5 to 300 mbar; Overpressure: 30 to 1.500 mbar; Remote indication as an option available'
                },
                {
                    title:'options',
                    description:'manual or remote relase of SSV, remote indication SSV „closed“ via induktive sensor or reed contact'
                }
            ],
            image:'pro_18.jpg',  
            pdfFileLink:''
        },
        { 
            pageName: 'medenus-rs254',
            mainHeading: 'MEDENUS RS254',
            secondryHeading:'<h4>MEDENUS GAS PRESSURE REGULATOR RS254</h4>', 
            contentFirst:'<p className="products_box_03_p1">The MEDENUS gas pressure regulator type RS254 ensures a constant outlet pressure - regardless of the primary (inlet) pressure - when the gas usage varies and ensures a quick pressure regulation when the flow rate abruptly changes. The inbuilt safety shut off valve ensures a safe shut off if the pressure is too high or too low.</p><p className="products_box_03_p2">The gas pressure regulator RS254 is suitable for gases in accordance with DVGW Code of Practice G260/262 and all other neutral and non-corrosive gases (others upon request).The RS254 is available with flange connections from DN25 to DN200 and working pressure up to 16,0 bar. All gas pressure regulators are certified by DVGW and CE.</p>',
            contentSecond:'',
            listItem:[
                {
                    title:'Application',
                    description:'Usable up to 16.0 bar maximum inlet pressure for all gases in accordance with DVGW Code of Practice G260/G262 and all non-corrosive gases (others upon request); outlet pressure: maximum 3 bar; temperature range: -20°C to + 60°C'
                },
                {
                    title:'Options',
                    description:'safety membrane or integrated safety relief valve, vent valve, noise reduction, nozzle valve for impulse connection, coatings in RAL colours and a special version for oxygen applications.'
                },
                {
                    title:'Characteristics',
                    description:'spring loaded single seated valve with integrated safety shut off valve, gas tight zero position and primary pressure equalisation; body material: alluminium cast alloy'
                },
                {
                    title:'Flange connections',
                    description:'PN16 for DN25, DN50, DN80, DN100, DN150 according to DIN EN 1092-4;according to ASME-B16.5-Class150RF'
                },
                {
                    title:'Integrated Shut off Value',
                    description:'Release: lack of pressure: 5-1.000 mbar; Overpressure: 30 - 4.500 mbar; Remote indication as an option available'
                },
                {
                    title:'options',
                    description:'manual or remote relase of SSV, remote indication SSV „closed“ via induktive sensor or reed contact'
                }
            ],
            image:'pro_18.jpg',
            pdfFileLink:''
        },
        { 
            pageName: 'medenus-rs255',
            mainHeading: 'MEDENUS RS255',
            secondryHeading:'<h4>MEDENUS GAS PRESSURE REGULATOR RS255</h4>', 
            contentFirst:'<p className="products_box_03_p1">The MEDENUS gas pressure regulator type RS255 ensures a constant outlet pressure - regardless of the primary (inlet) pressure - when the gas usage varies and ensures a quick pressure regulation when the flow rate abruptly changes. The inbuilt safety shut off valve ensures a safe shut off if the pressure is too high or too low.</p><p className="products_box_03_p2">The gas pressure regulator RS255 is suitable for gases in accordance with DVGW Code of Practice G260/262 and all other neutral and non-corrosive gases (others upon request).The RS255 is available with flange connections from DN 50 to DN100 and working pressure up to 16,0 bar. All gas pressure regulators are certified by DVGW and CE.</p><p className="products_box_03_p2">All regulators of the RS-series are with options like safety membrane, vent valve, remote release of SSV, noise reduction and many more available. For any details please look into our documentation or get in contact with us.</p>',
            contentSecond:'',
            listItem:[
                {
                    title:'Application',
                    description:'Usable up to 16.0 bar maximum inlet pressure for all gases in accordance with DVGW Code of Practice G260/G262 and all non-corrosive gases (others upon request); outlet pressure: maximum 3 bar; temperature range: -20°C to + 60°C'
                },
                {
                    title:'Options',
                    description:'safety membrane or integrated safety relief valve, vent valve, noise reduction, nozzle valve for impulse connection, coatings in RAL colours and a special version for oxygen applications.'
                },
                {
                    title:'Characteristics',
                    description:'spring loaded single seated valve with integrated safety shut off valve, gas tight zero position and primary pressure equalisation; body material: alluminium cast alloy'
                },
                {
                    title:'Flange connections',
                    description:'PN16 for DN50, DN80, DN100 according to DIN EN 1092-4; according to ASME-B16.5-Class150RF'
                },
                {
                    title:'Integrated Shut off Value',
                    description:'Release: lack of pressure: 5 to 1.000 mbar; Overpressure: 30 to 4.500 mbar; Remote indication as an option available'
                },
                {
                    title:'options',
                    description:'manual or remote relase of SSV, remote indication SSV „closed“ via induktive sensor or reed contact'
                }
            ],
            image:'pro_18.jpg',
            
            pdfFileLink:''
        },
        { 
            pageName: 'medenus-rsp254',
            mainHeading: 'MEDENUS RSP254',
            secondryHeading:'<h4>MEDENUS RSP254</h4>', 
            contentFirst:'<p className="products_box_03_p1">The pilot operated regulators series RSP254/255 are applicable to applications for outlet pressures from 10 mbar up to 12 bar and needed accuracy class up to 1%. With 3 different versions of pilots we always have the perfect match to the requirements of your projects up to 16 bar inlet pressure. The pilot R70-10 is used for high pressure applications from 500 mbar to 12 bar outlet pressure especially needed in the industrial area, gas furnaces etc. but also in the gas distribution and for manufacturers of gas engines. R70-20 is developed as a special device with an integrated 1:2 booster and an external I/P converter (option) we can realize follow set point systems for gas engines. Pilot R70-100 is the perfect solution when it comes to low pressure applications from 10 mbar to 500 mbar outlet pressure with very high accuracy. R70-100 is mainly used in the city gas distribution but also in the industrial area.</p>',
            contentSecond:'',
            listItem:[
                {
                    title:'Application',
                    description:'Suitable for gases in accordance with DVGW Code of Practice G260/262 and all other neutral and non-corrosive gases (others upon request) up to a maximum inlet pressure of 16 bar.Outlet pressure up to 12 bar Gas temperature from -20 °C to + 60 °C'
                },
                {
                    title:'Characteristics',
                    description:'Pilot operated actuator with auxilary power with or without integrated safets shut off valve. Tight zero position. Material: aluminium sand cast'
                },
                {
                        title:'Type of Pilots',
                        description:'R70-10: Outlet pressure 0,5 bar up to 12 bar for high pressure applications R70-20: 0,1 bar up to 6 bar (1:1) with optional integrated booster 1:2 ( 0,2 - 12 bar) and optional I/P converter for the gas engine industry R70-100: Outlet pressure 10 mbar up to 500 mbar for applications with low outlet pressure and high accuracy, e.g. for city gas distribution.'
                },
                {
                    title:'Flange connections',
                    description:'DN25 - DN200 according to DIN EN 1092 or ASME 16.5 B Class 150RF'
                },
                {
                    title:'Options',
                    description:'Available with: Vent valve (BV), noise reduction (SR), nozzle valve (RSD), I/P Umformer. Available also with RAL coating or as special version for oxygen.'
                },
                {
                    title:'Integrated Safety Shut off Value',
                    description:'Release: lack of pressure: 5 to 1.000 mbar Overpressure: 30 to 12.500 mbar Optional with remote indication via Reed contact or inductive sensor. Above 4.000 mbar overpressure shut off the additional device K70 is needed.'
                },
                {
                    title:'Options Safety Shut off Value',
                    description:'Available with manual release, remote release or both. Optional alos with remote indication with Reed contact or inductive sensor.'
                }
            ],
            image:'pro_22.jpg',
            pdfFileLink:''
        },
        { 
            pageName: 'medenus-rsp255',
            mainHeading: 'MEDENUS RSP255',
            secondryHeading:'<h4>MEDENUS RSP255</h4>', 
            contentFirst:'<p className="products_box_03_p1">The pilot operated regulators series RSP254/255 are applicable to applications for outlet pressures from 10 mbar up to 12 bar and needed accuracy class up to 1%. With 3 different versions of pilots we always have the perfect match to the requirements of your projects up to 16 bar inlet pressure. The pilot R70-10 is used for high pressure applications from 500 mbar to 12 bar outlet pressure especially needed in the industrial area, gas furnaces etc. but also in the gas distribution and for manufacturers of gas engines. R70-20 is developed as a special device with an integrated 1:2 booster and an external I/P converter (option) we can realize follow set point systems for gas engines. Pilot R70-100 is the perfect solution when it comes to low pressure applications from 10 mbar to 500 mbar outlet pressure with very high accuracy. R70-100 is mainly used in the city gas distribution but also in the industrial area.</p>',
            contentSecond:'',
            listItem:[
                {
                    title:'Application',
                    description:'Suitable for gases in accordance with DVGW Code of Practice G260/262 and all other neutral and non-corrosive gases (others upon request) up to a maximum inlet pressure of 16 bar.Outlet pressure up to 12 bar Gas temperature from -20 °C to + 60 °C'
                },
                {
                    title:'Characteristics',
                    description:'pilot operated actuator with auxilary power with or without integrated safets shut off valve. Tight zero position. Material: aluminium sand cast'
                },
                {
                        title:'Type of Pilots',
                        description:'R70-10: Outlet pressure 0,5 bar up to 12 bar for high pressure applications R70-20: 0,1 bar up to 6 bar (1:1) with optional integrated booster 1:2 ( 0,2 - 12 bar) and optional I/P converter for the gas engine industry R70-100: Outlet pressure 10 mbar up to 500 mbar for applications with low outlet pressure and high accuracy, e.g. for city gas distribution.'
                },
                {
                    title:'Flange connections',
                    description:'DN50 - DN100 according to DIN EN 1092 or ASME 16.5 B Class 150RF'
                },
                {
                    title:'Options',
                     description:'Available with: Vent valve (BV), noise reduction (SR), nozzle valve (RSD), I/P Umformer. Available also with RAL coating or as special version for oxygen.'
                },
                {
                    title:'Integrated Safety Shut off Value',
                    description:'Release: lack of pressure: 5 to 1.000 mbar Overpressure: 30 to 12.500 mbar Optional with remote indication via Reed contact or inductive sensor. Above 4.000 mbar overpressure shut off the additional device K70 is needed.'
                },
                {
                    title:'Options Safety Shut off Value',
                    description:'Available with manual release, remote release or both. Optional alos with remote indication with Reed contact or inductive sensor.'
                }
            ],
            image:'pro_24.jpg',
            pdfFileLink:''
        },
        { 
            pageName: 'medenus_sl_10',
            mainHeading: 'Medenus SL10',
            secondryHeading:'<h4>Medenus SL10</h4>', 
            contentFirst:'<p className="products_box_03_p1">The safety relief valve SL10 is mainly used to blow off a small amount of gas when the pressure is slowly increasing and to avoid that the safety shut-off valves releases.</p><p className="products_box_03_p2">The safety relief valve SL10 is suitable for gases in accordance with DVGW Code of Practice G260/262 and all other neutral and non-corrosive gases (others upon request). The SL10 is available with thread connections Rp 1", Rp 1,5", Rp 2" and blow off pressure up to 3,5 bar. All safety relief valves are certified by DVGW and CE.</p>',
            contentSecond:'',
            listItem:[
                {
                    title:'Response Pressure',
                    description:'from 25 to 3.500 mbar'
                },
                {
                    title:'Application',
                    description:'Usable up to 3.5 bar maximum inlet pressure for all gases in accordance with DVGW Code of Practice G260/G262 and all non-corrosive gases (others upon request); temperature range: -20°C to + 60°C, function class A'
                },
                {
                    title:'Characteristics',
                    description:'spring loaded single seated valve with gas tight zero position; body material: alluminium cast alloy'
                },
                {
                    title:'Connections',
                    description:'Rp 1", Rp 1 ½", Rp 2"'
                }
            ],
            image:'PRO25.jpg',
            pdfFileLink:''
        },
        { 
            pageName: 'medenus_s50',
            mainHeading: 'Medenus S50',
            secondryHeading:'<h4>Medenus S50</h4>', 
            contentFirst:'<p className="products_box_03_p1">The S50 Safety Shut Off valve is mainly used to avoid any damage to components as well as to avoid too high or too low pressure in the gas train. This could cause high financial losses and/or injured people.</p><p className="products_box_03_p2">The safety shut off valve S50 is suitable for gases in accordance with DVGW Code of Practice G260/262 and all other neutral and non-corrosive gases (others upon request).The S50 is available with thread connections Rp 1", Rp 1,5", Rp 2" and working pressure up to 3,0 bar. The safety shut off valves are certified by DVGW and CE.</p>',
            contentSecond:'',
            listItem:[
                {
                    title:'Connections',
                    description:'Rp 1", Rp 1 ½", Rp 2"'
                },
                {
                    title:'Einsatzgebiete',
                    description:'Usable up to 3.0 bar maximum inlet pressure for all gases in accordance with DVGW Code of Practice G260/G262 and all non-corrosive gases (others upon request); temperature range: -20°C to + 60°C, function class A'
                },
                {
                    title:'Characteristics',
                    description:'spring loaded single seated valve with gas tight zero position; remote indication as an option, body material: alluminium cast alloy'
                },
                {
                    title:'Release',
                    description:'lack of pressure: 10 to 300 mbar; Overpressure: 50 to 1.500 mbar'
                }
            ],
            image:'pro26.jpg',
            pdfFileLink:''
        },
        { 
            pageName: 'medenus_s100',
            mainHeading: 'Medenus S100',
            secondryHeading:'<h4>Medenus S100</h4>', 
            contentFirst:'<p className="products_box_03_p1">The S100 Safety Shut Off valve is mainly used to avoid any damage to components as well as to avoid too high or too low pressure in the gas train. This could cause high financial losses and/or injured people.</p><p className="products_box_03_p2">The safety shut off valve S100 is suitable for gases in accordance with DVGW Code of Practice G260/262 and all other neutral and non-corrosive gases (others upon request).The S100 is available with flange connections DN25 to DN200 and working pressure up to 8,0 bar. All safety shut off valves are certified by DVGW and CE.</p>',
            contentSecond:'',
            listItem:[
                {
                    title:'Flange Connections',
                    description:'PN16 for DN25, DN40, DN50, DN65, DN80, DN100, DN150 according to DIN EN 1092-4; according to ASME-B16.5-Class150RF'
                },
                {
                    title:'Applications',
                    description:'Usable up to 8.0 bar maximum inlet pressure for all gases in accordance with DVGW Code of Practice G260/G262 and all non-corrosive gases (others upon request); temperature range: -20°C to + 60°C, function class A'
                },
                {
                    title:'Characteristics',
                    description:'spring loaded single seated valve with gas tight zero position; remote indication as an option, body material: alluminium cast alloy'
                },
                {
                    title:'Release',
                    description:'lack of pressure: 10 to 300 mbar; Overpressure: 50 to 1.500 mbar'
                }
            ],
            image:'pro27.jpg',
            pdfFileLink:''
        },
        { 
            pageName: 'MEDENUS_CELLULAR_GAS_FILTER_DF_100',
            mainHeading: 'Medenus Cellular Gas Filter Df 100',
            secondryHeading:'<h4>Medenus Cellular Gas Filter Df 100</h4>', 
            contentFirst:'<p className="products_box_03_p1">The new MEDENUS cellular gasfilter series DF100 are designed for retaining gas impurities, such as dust, rust and other solid particles at a defined location. They are mainly used in gas pressure and measuring stations and upstream of equipment, the function of which would be influenced by contaminants.</p><p className="products_box_03_p2">These filters are suitable for gases in accordance with DVGW Code of Practice G260/262 and all other neutral and non-corrosive gases. (other gases on request). The series is certified by DVGW,CE and EAC.</p>',
            contentSecond:'',
            listItem:[
                {
                    title:'Filter cartridge',
                    description:'Easily replaceable filter cartridges with hydrophobic filter medium. Filtration efficiency 99,9% of particle size 2µm (standard, others on request). Differential pressure value (∆p) for filter cartridge change 800-1.000 mbar, maximum limit ∆p 2.000mbar. Dust storage capacity 125 (135) mg/cm²'
                },
                {
                    title:'Characteristics',
                    description:'Aluminium-cas alloy suitable up to 16bar; outdoor version standard; Temperature range -20°C bis + 70°C (-40°C available); function, strength and leakproofed according to DIN3386, DVGW Code of Practice G498 and DIN30690-1'
                },
                {
                    title:'Connections - flangs',
                    description:'flanges DIN PN16 in sizes DN25 – DN200'
                },
                {
                    title:'Features',
                    description:'differential pressure gauge with or without reed contact available, with 2 pcs. Shut off ball valves for the pressure gauge; Low temperature version down to -40°C available.'
                }
            ],
            image:'pro28.jpg',
            pdfFileLink:''
        },
        { 
            pageName: 'medenus_turbine_meters_quantometer',
            mainHeading: 'Medenus Turbine Meters (Quantometer)',
            secondryHeading:'<h4>Medenus Turbine Meters (Quantometer)</h4>', 
            contentFirst:'<p className="products_box_03_p1">The turbine meters (quantometer) are used for the measurement of volumes of all gases according to DVGW G260 / 262 and all other non aggessiven gases (others upon request). They are mainly used for internal measurements or control tasks.</p><p className="products_box_03_p2">The Quantometer or Volumeter is a turbine gas meter and registers the actual volume with an eight-digit mechanical roller counter. With the LF of HF pulser (reed contact) the volume can be transmitted to a volume corrector or a controller system to convert the volume to standard cubic meters.</p>',
            contentSecond:'',
            listItem:[
                {
                    title:'Application',
                    description:'The turbine meters can be used up to a maximum pressure of 20 bar for all gases according to DVGW G260 / 262 and all other non-aggressive gases (others on request). Gas temperature -20 ° C to + 60 ° C, ambient temperature -25 ° C to + 55 ° C.'
                },
                {
                    title:'Characteristics',
                    description:'Turbine meters with hard anodized aluminum housing and a turbine wheel made of aluminum alloy. By default, the case is equipped with a 1/4 inch NPT threaded connection for pressure transmiters, as an option with one or two pockets for temperature sensors.'
                },
                {
                    title:'Index head',
                    description:'Standardly made of a polymer material, optional made of Aluminium. The counter is an 8 digit mechanical roller counter with a LF pulser (reed contact) and an anti-manipulation contact fitted as standard. Optional are one or two HF pulse available.'
                },
                {
                    title:'connections',
                    description:'sizes DN25 to DN150'
                },
                {
                    title:'turbine wheel',
                    description:'The turbine wheel is standardly made of aluminum and has permanently lubricated bearings. If desired, the gas meter can be equipped with a mechanical oil pump. (e.g. for biogas applications)'
                }
            ],
            image:'pro30.jpg',
            pdfFileLink:''
        },
        { 
            pageName: 'ELECTRONIC_TURBINE_METER_MQME_QUANTOMETER',
            mainHeading: 'Electronic Turbine Metter Mqme - Quantometer',
            secondryHeading:'<h4>Electronic Turbine Metter Mqme - Quantometer</h4>', 
            contentFirst:'<p className="products_box_03_p1">The MQMe Quantometer is a turbine gas meter that registers the operating volume using a nine-digit electronic index. The flow of the gas to be measured causes the turbine rotor to rotate. The gas flow is narrowed on an annular cross section, is accelerated and directed onto the smooth-running Aluminum rotor. The number of rotations is proportional to the measured gas volume; the frequency of rotations is proportional to the actual gas flow.</p><p className="products_box_03_p2">The rotation of the rotor is transmitted via a magnetoresistance sensor from the gas pressurized area to the electronic index which is in the atmospheric environment. The CPU is receiving the high frequency signal for the magnetoresistance sensor to calculate the gas flow and gas volume under operating conditions. If the optional electronic volume corrector function is installed the gas flow and gas volume under standard conditions will be calculated according AGA NX-19. The calculation can be based on fixed factors for temperature and pressure or on optionally installed temperature and pressure sensors. The MQMe is designed to have one external temperature and one external pressure transmitter installed directly in the meter.</p>',
            contentSecond:'',
            listItem:[
                {
                    title:'Characteristics',
                    description:'<ul><li>several options to transmit the measured an calculated data to a digital control system (DCS) or SCADA<li><li>equipped with alternatively RS 485 or M-Bus interface and one high frequency (HF) as well as one low frequency (LF) pulser,</li><li>if an external power supply is connected to the MQME one 4 to 20 mA configurable signal is available</li><li>the rotation of the rotor can be scannes additionally with one external high frequency (HF) sensor</li> <li>the HF-sensor signal allows the determination of the actual gas flow in high-resolution and can be transmit to any digital control system (DCS) or SCADA for flow control purposes</li><li>integrated electronic volume corrector</li>'
                },
                {
                    title:'Options',
                    description:'<ul style="margin-left:20px;"> <li>WFactory calibration measuring range 1:20</li><li>integrated volume corrector (incl. pressure &amp; temperature sensors)</li><li>analog output (4-20 mA, 24 VDC needed)</li><li>RS485 (M-bus not possible)</li><li>M-Bus (RS485 not possible)</li><li>Oil pump</li><li>1x thermowell</li><li>Test certificate 3.1 with detailed materials list</li></ul>'
                }
            ],
            image:'pro29.jpg',
            pdfFileLink:''
        },
        { 
            pageName: 'MEDENUS_gas_ball_valve',
            mainHeading: 'Medenus Gas Ball Valve',
            secondryHeading:'<h4>Medenus Gas Ball Valve</h4>', 
            contentFirst:'<p className="products_box_03_p1">Gas ball valves are mainly used to shut off the gas flow in gas trains or gas installations before and after other equipment like gas pressure regulators, gas meters etc.</p><p className="products_box_03_p2">These ball valves are suitable for all gases according to DVGW G260 / G262 and all other non aggessiven gases (others upon request). They are certified according to DVGW, DIN EN 13774, PED, GAD, CE.</p>',
            contentSecond:'',
            listItem:[
                {
                    title:'Application',
                    description:'These gas ball valves can be used up to a maximum pressure of 16 bar for all gases according to DVGW G260 / 262 and all other non-aggressive gases (others on request). Gas temperature -20 ° C to + 60 ° C.'
                },
                {
                    title:'housing',
                    description:'Two-piece, bolted spheroidal cast iron housing with full port design'
                },
                {
                    title:'connections',
                    description:'flange dimensions according to DN EN 1092-2 PN16, faces according to EN 1092-1 B1'
                }
            ],
            image:'pro31.jpg',
            pdfFileLink:''
        }
    ];