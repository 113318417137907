import React, {Component} from 'react';

import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import InnerPageBanner from '../Layout/InnerPageBanner';
import Breadcrumb from '../Layout/Breadcrumb';

class Services extends Component{

    componentDidMount() {
        document.body.style.background = '#ffffff';
    }
    render(){
        return(
            <div>

            <div className="header" id="home">
                
                <Header />        
                    
                    
            </div>
            <InnerPageBanner />
            <Breadcrumb Breadcrumb="SERVICE" />
            <div class="container_fluid main_padd">
                <div class="container">
                    
                    <h3 class="main_heading">SERVICE</h3>
                    <div class="row products_box">
                        <div class="col-md-12">
                            <h4 styles={{marginTop:'20px'}}>All ELCO products are backed up by a service network that sets standards in the industry
                            </h4>
                            <h5 styles={{fontSize:'18px',margin:'20px 5px 0px 0px'}}>Commissioning</h5>
                            <p styles={{padding:'5px 0px 0px 0px'}}>For safe and efficient operation of your burner system it is very important that the burner is commissioned by an expert. The combustion will be optimally adjusted over the whole power range of the burner, and all the safeties will be tested. Of course you will receive a report. ELCO’s service technicians are able, like no other, in performing this job adequate and competent, to run your installation worry-free.</p>
                            <h5 styles={{fontSize:'18px;margin:20px 5px 0px 0px'}}>Maintenance and inspection </h5>
                            <p>The burner is a crucial part of the installation. To keep your installation in good conditions, it is important to maintain the burner periodically. It is also very important to inspect all the safeties to ensure that your system operates safely. </p>
                            <p styles={{padding:'5px 0px 0px 0px'}}>Fortunately, you can rely on the professional services of ELCO which can perfectly perform this service for you. </p>
                            <h5 styles={{fontSize:'18px',margin:'20px 5px 0px 0px'}}>Professional training </h5>
                            <p>ELCO is developing a worldwide network of valuable Service Partners, consisting of well-trained local engineers, to carry out its service operations. To keep them always updated on its new products and technologies, the Burner Academy provides the opportunity to attend training sessions carried out by highly qualified instructors.</p>
                            </div>
                    </div>
                </div>
            </div>
                
            <Footer />

            </div>
        )
    }

}


export default Services;