import './App.css';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import Layout  from './Layout/Layout';
import AboutUs  from './AboutUs/AboutUs';
import ContactUs  from './ContactUs/ContactUs';
import CommercialDocumentation  from './Catalogue/CommercialDocumentation/CommercialDocumentation';
import TechnicalCatalogue from './Catalogue/TechnicalCatalogue/TechnicalCatalogue';
import Enquiry  from './Enquiry/Enquiry';
import Services  from './Services/Services';
import IndustrialBurners  from './IndustrialBurners/IndustrialBurners';
import File  from './IndustrialBurners/open/File';

import GasPressureRegulators from './GasPressureRegulators/GasPressureRegulators';
import GasPressureRegulatorsFile from './GasPressureRegulators/open/GasPressureRegulatorsFile';

function App() {
  return (
        // <Router>
        //   <div>
        //     <Switch>
        //         <Route exact path='/' component={Layout} />
        //         <Route path='/about-us' component={AboutUs} />
        //         <Route path='/contact-us' component={ContactUs} />
        //         <Route path='/enquiry' component={Enquiry} />
        //         <Route path='/service' component={Services} />
        //         <Route path='/industrial-burners' component={IndustrialBurners} />
        //         <Route path='/industrial-burners/:name' component={File} />
        //     </Switch>
        //   </div>
        // </Router>
    <Router>
      <div>
        <Switch>
          <Route path="/about-us">
            <AboutUs />
          </Route>

          <Route path="/contact-us">
            <ContactUs />
          </Route>

          <Route path="/technical-catalogues">
            <TechnicalCatalogue />
          </Route>

          <Route path="/commercial-documentation">
            <CommercialDocumentation />
          </Route>

          <Route path="/enquiry">
            <Enquiry />
          </Route>

          <Route path="/service">
            <Services />
          </Route>

          <Route path="/industrial-burners/:id">
            <File />
          </Route>
          <Route path="/industrial-burners">
            <IndustrialBurners />
          </Route>
          

          <Route path="/gas-pressure-regulators/:id">
            <GasPressureRegulatorsFile />
          </Route>
          <Route path="/gas-pressure-regulators">
            <GasPressureRegulators />
          </Route>

          <Route path="/">
            <Layout />
          </Route>
          
        </Switch>
      </div>
    </Router>
  );
}

export default App;
