import React, { Component, Suspense } from 'react';  

import Header from './Header'  
import Footer from './Footer'  

export class Layout extends Component {  
    loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>  
    render() {  
        return (  
            <div>

            <div className="header" id="home">
                
                <Header />        
                    <div className="slider">
                        <div className="callbacks_container">
                            <ul className="rslides callbacks callbacks1" id="slider4">
                                <li>
                                    <div className="banner-top">
                                        
                                    </div>
                                </li>
                                <li>
                                    <div className="banner-top1">

                                    </div>
                                </li>
                                <li>
                                    <div className="banner-top2">
                                        
                                    </div>
                                </li>
                                <li>
                                    <div className="banner-top3">
                                    
                                    </div>
                                </li>
                                <li>
                                    <div className="banner-top4">
                                    
                                    </div>
                                </li>
                                <li>
                                    <div className="banner-top5">
                                    
                                    </div>
                                </li>
                                <li>
                                    <div className="banner-top6">
                                    
                                    </div>
                                </li>
                                <li>
                                    <div className="banner-top7">
                                    
                                    </div>
                                </li>
                                <li>
                                    <div className="banner-top8">
                                    
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="clearfix"> </div>
                    </div>
                    
                </div>
                
                <div className="banner_bottom y">
                    <div className="container">
                        <div className="news-main">
                            <div className="col-md-6 news-left">
                                <div className="col-md-6 b_left">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/ab.jpg"} alt=" " className="img-responsive" />

                                </div>
                                <div className="col-md-6 b_right">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/ab1.jpg"} alt=" " className="img-responsive" />

                                </div>
                            </div>
                            <div className="col-md-6 news-right">
                                <h4>About Us</h4>
                                <p className="sub_p">We <strong><b className="b">Euromax International</b></strong> (An <strong><b className="b">ISO 9001:2015</b></strong> Certified Company) a introduce ourselves as Official distributor of Elco, GmbH-Germany supply for Industrial Burners & Medenus Gas-Druckregeltechnik GmbH, Germany for Gas Pressure regulators and gas Train. 
                                </p>
                                <p>The moto of our company is to provide complete turnkey solutions in the field of fuel handling in terms of Reliability, Affordability and Efficiency. Our impetus will be total solutions with guaranteed ROI. We provide fuel handling system of Liquid and Gas with reliable products for Retrofits on existing system, as well as conversions oil into gas of existing firing equipments like boiler and burners. 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                
                {/* <div className="history">
                    <div className="container">
                

                        <h3 className="tittle two">Our History</h3>
                        <div className="inner_sec_info_wthree_agile">
                        
                            <div className="col-md-3 stats_left counter_grid">
                                <i className="fa fa-users" aria-hidden="true"></i>
                                <p className="counter">50</p>
                                <h4>employee</h4>
                            </div>
                            <div className="col-md-3 stats_left counter_grid1">
                                <i className="fa fa-users" aria-hidden="true"></i>
                                <p className="counter">365</p>
                                <h4>customer</h4>
                            </div>
                            <div className="col-md-3 stats_left counter_grid2">
                                <i className="fa fa-star" aria-hidden="true"></i>
                                <p className="counter">4.2</p>
                                <h4>Google Rating</h4>
                            </div>
                            <div className="col-md-3 stats_left counter_grid3">
                                <i className="fa fa-headphones" aria-hidden="true"></i>
                                <p className="counter">1045</p>
                                <h4>Customer Support</h4>
                            </div>
                            <div className="clearfix"> </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div> */}
                    
                
                
    
                    
                    
                    <div className="mid_slider y">
                        <div className="mid_slider_info">
                            <h3 className="tittle">Our Gallery</h3>
                            <div className="inner_sec_info_wthree_agile">
                            
                                <div id="myCarousel" className="carousel slide" data-ride="carousel">
                            
                                    <ol className="carousel-indicators">
                                        <li data-target="#myCarousel" data-slide-to="0" className="active"></li>
                                        <li data-target="#myCarousel" data-slide-to="1" className=""></li>
                                        <li data-target="#myCarousel" data-slide-to="2" className=""></li>
                                        <li data-target="#myCarousel" data-slide-to="3" className=""></li>
                                    </ol>
                                    <div className="carousel-inner" role="listbox">
                                        <div className="item active">
                                            <div className="row">
                                                <div className="col-md-3 col-sm-3 col-xs-3 slidering">
                                                    <div className="thumbnail"><img src={process.env.PUBLIC_URL + "/assets/images/p1.jpg"} alt="Image" styles={{maxWidth:'100%'}} /></div>
                                                </div>
                                                <div className="col-md-3 col-sm-3 col-xs-3 slidering">
                                                    <div className="thumbnail"><img src={process.env.PUBLIC_URL + "/assets/images/p2.jpg"} alt="Image" styles="max-width:100%;" /></div>
                                                </div>
                                                <div className="col-md-3 col-sm-3 col-xs-3 slidering">
                                                    <div className="thumbnail"><img src={process.env.PUBLIC_URL + "/assets/images/p3.jpg"} alt="Image" styles={{maxWidth:'100%'}} /></div>
                                                </div>
                                                <div className="col-md-3 col-sm-3 col-xs-3 slidering">
                                                    <div className="thumbnail"><img src={process.env.PUBLIC_URL + "/assets/images/p4.jpg"} alt="Image" styles={{maxWidth:'100%'}} /></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="row">
                                                <div className="col-md-3 col-sm-3 col-xs-3 slidering">
                                                    <div className="thumbnail"><img src={process.env.PUBLIC_URL + "/assets/images/b1.jpg"} alt="Image" styles={{maxWidth:'100%'}} /></div>
                                                </div>
                                                <div className="col-md-3 col-sm-3 col-xs-3 slidering">
                                                    <div className="thumbnail"><img src={process.env.PUBLIC_URL + "/assets/images/b2.jpg"} alt="Image" styles={{maxWidth:'100%'}} /></div>
                                                </div>
                                                <div className="col-md-3 col-sm-3 col-xs-3 slidering">
                                                    <div className="thumbnail"><img src={process.env.PUBLIC_URL + "/assets/images/b3.jpg"} alt="Image" styles={{maxWidth:'100%'}} /></div>
                                                </div>
                                                <div className="col-md-3 col-sm-3 col-xs-3 slidering">
                                                    <div className="thumbnail"><img src={process.env.PUBLIC_URL + "/assets/images/b4.jpg"} alt="Image" styles={{maxWidth:'100%'}} /></div>
                                                </div>
                                            </div>
                                        </div>
                            
                                    </div>
                                    <a className="left carousel-control" href="#myCarousel" role="button" data-slide="prev">
                                        <span className="fa fa-chevron-left" aria-hidden="true"></span>
                                        <span className="sr-only">Previous</span>
                                    </a>
                                    <a className="right carousel-control" href="#myCarousel" role="button" data-slide="next">
                                        <span className="fa fa-chevron-right" aria-hidden="true"></span>
                                        <span className="sr-only">Next</span>
                                    </a>
                            
                                </div>
                            </div>
                        </div>
                    </div>
            
                <Footer />

            </div>
        )  
    }  
}  
  
export default Layout