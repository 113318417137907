import React, {Component} from 'react';

import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import InnerPageBanner from '../Layout/InnerPageBanner';
import Breadcrumb from '../Layout/Breadcrumb';

class ContactUs extends Component{

    componentDidMount() {
        document.body.style.background = '#ffffff';
    }


    render(){
        return(
            <div>

            <div className="header" id="home">
                
                <Header />        
                    
                    
            </div>
            <InnerPageBanner />
            <Breadcrumb Breadcrumb="Enquiry" />
            <div class="banner_bottom">
                <div class="container">
                    <div class="tittle_head">
                        
                    </div>
                    

                    <div class="mail_form">
                        <h3 class="tittle mail">Send Us <span>a Query </span></h3>
                        <div class="inner_sec_info_wthree_agile">
                            <form action="#" method="post">
                                <span class="input input--chisato">
                                <input class="input__field input__field--chisato" name="Name" type="text" id="input-13" placeholder=" " required="" />
                                <label class="input__label input__label--chisato" for="input-13">
                                    <span class="input__label-content input__label-content--chisato" data-content="Name">Name</span>
                                </label>
                                </span>
                                <span class="input input--chisato">
                                <input class="input__field input__field--chisato" name="Email" type="email" id="input-14" placeholder=" " required="" />
                                <label class="input__label input__label--chisato" for="input-14">
                                    <span class="input__label-content input__label-content--chisato" data-content="Email">Email</span>
                                </label>
                                </span>
                                <span class="input input--chisato">
                                <input class="input__field input__field--chisato" name="Subject" type="text" id="input-15" placeholder=" " required="" />
                                <label class="input__label input__label--chisato" for="input-15">
                                    <span class="input__label-content input__label-content--chisato" data-content="Subject">Subject</span>
                                </label>
                                </span>
                                <textarea name="Message" placeholder="Your comment here..." required=""></textarea>
                                <input type="submit" value="Submit" />
                            </form>

                        </div>
                    </div>
                    <div class="clearfix"> </div>

                </div>
            </div>
                        
	
            
                <Footer />

            </div>
        )
    }

}


export default ContactUs;