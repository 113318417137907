import React, { Component } from 'react';

import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import InnerPageBanner from '../Layout/InnerPageBanner';
import Breadcrumb from '../Layout/Breadcrumb';

class ContactUs extends Component {

	componentDidMount() {
		document.body.style.background = '#ffffff';
	}

	render() {
		return (
			<div>

				<div className="header" id="home">
					<Header />
				</div>
				<div className="container section">
					<div className="row">
					<div className="col-md-6">
					<div className="map">
						<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.810132276755!2d77.30835581455966!3d28.63545209070073!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfba048aaa4c5%3A0xe057a6cd6e55119a!2sEuromax+International!5e0!3m2!1sen!2sin!4v1559195099252!5m2!1sen!2sin" height="350px;"></iframe>
					<div className="row">
					<div className="col-md-12">
						<h3>Euromax International</h3>
						<p>52C, Ground Floor, Krishna Complex, Hasanpur, Delhi - 110092, India</p>
								<p><b>Phone No.</b>+91-11-49046567, +91-9711139301</p>
								<p><b>Fax No.</b>+91-11-49046567</p>
								<p><b>Email Address </b> <a href="mailto:info@euromaxglobal.com">info@euromaxglobal.com</a></p>
					</div>
					</div>
					</div>
					</div>

					<div className="col-md-6">
						<h3>Let us Connect Now</h3>
						<div className="mail_form">
						<form action="#" method="post">
								<span className="input input--chisato">
									<input className="input__field input__field--chisato" name="Name" type="text" id="input-13" placeholder=" " required="" />
									<label className="input__label input__label--chisato" for="input-13">
										<span className="input__label-content input__label-content--chisato" data-content="Name">Name</span>
									</label>
								</span>
								<span className="input input--chisato">
									<input className="input__field input__field--chisato" name="Email" type="email" id="input-14" placeholder=" " required="" />
									<label className="input__label input__label--chisato" for="input-14">
										<span className="input__label-content input__label-content--chisato" data-content="Email">Email</span>
									</label>
								</span>
								<span className="input input--chisato">
									<input className="input__field input__field--chisato" name="Subject" type="text" id="input-15" placeholder=" " required="" />
									<label className="input__label input__label--chisato" for="input-15">
										<span className="input__label-content input__label-content--chisato" data-content="Subject">Subject</span>
									</label>
								</span>
								<textarea name="Message" placeholder="Your comment here..." required=""></textarea>
								<input type="submit" value="Submit" />
							</form>
						</div>
						
					</div>
					</div>
				</div>
				<Footer />

			</div>
		)
	}

}


export default ContactUs;