export const jsonData = [
        { 
            pageName: 'vectron',
            mainHeading: 'VECTRON',
            secondryHeading:'<h4>Monoblock burners from 11 kW to 2300 kW in gas, light oil and dual fuel</h4>', 
            upperContent: '<p styles={{padding:"0px 10px 0px 0px"}}>- The carachteristic cubic type architecture allows optimal accessibility and compactness to all models, simplifing all the installation and maintenance activities </p><p styles={{padding:"5px 10px 0px 0px"}}>- Low NOx emissions versions are available and capable not only to respect but also to exceed the requirements of the most stringent European directives</p>', 
            lowerContent: '<ul> <li>Complete electrical equipment in the body of the burner with MDE2 System and display giving real-time information during the commissioning, the operation and at each operation cycle</li><li>Quiet ventilation and reduced electrical consumption</li><li>Maintenance operation are simplified: the combustion parts can be quickly removed, easily cleaned and easily get back to their position after all the servicing work</li><li>Products are in compliance with EN676 and EN267 European standards and with the following directives: 2016/426/EU Gas Appliances Directive, 2014/30/EU EMC Directive, 2014/35/EU Low Voltage Directive, 2006/42/EC Efficiency Directive, 2011/65/EU RoHS2 Directive</li></ul>', 
            sideBar:'<a class="btn-default" href="#"><i class="fa fa-fire" aria-hidden="true"></i>Natural Gas</a> <a class="btn-default" href="#"><i class="fa fa-fire" aria-hidden="true"></i>Dual Fuel</a> <a class="btn-default" href="#"><i class="fa fa-fire" aria-hidden="true"></i>Light Oil</a> ', 
            image:'pro_01.jpg',
            imageBelowTitle:'All VECTRON burners feature:',
            pdfFileLink:'MKT_230117_Vectron_EN_1.9.pdf'
        },
        { 
            pageName: 'protron', 
            mainHeading: 'PROTRON', 
            secondryHeading:'<h4>Monoblock burners from 17 kW to 550 kW in gas and light oil</h4>', 
            upperContent: '<p styles={{padding:"0px 10px 0px 0px"}}>- Heat resistant, compact layout, easy maintenance, integral protection cover, graphic display: the PROTRON range has been especially designed for light industry applications</p><p styles={{padding:"0px 10px 0px 0px"}}>- PROTRON burners are characterized by high versatility allowing this range to be used in several installations within the process industry: ovens, dryers, spray booths, incinerators, hot air generators</p>', 
            lowerContent: '<ul> <li> 8 combinations of software programming to satisfy any application requirement with options like: Quick Start System, tightness control, adjustable pre-purge and post-purge, permanent ventilation.</li><li>burners configurated in factory by our technicians</li><li>quiet ventilation and reduced electrical consumption</li><li>complete electrical equipment in the body of the burner with control panel</li><li>products are in compliance with EN676 and EN267 European standards and with the following directives: 2016/426/EU Gas Appliances Directive, 2014/30/EU EMC Directive, 2014/35/EU Low Voltage Directive, 2006/42/EC Efficiency Directive, 2011/65/EU RoHS2 Directive!</li>', 
            sideBar:'<a class="btn-default" href="#"><i class="fa fa-fire" aria-hidden="true"></i>Natural Gas</a> <a class="btn-default" href="#"><i class="fa fa-fire" aria-hidden="true"></i>Dual Fuel</a> <a class="btn-default" href="#"><i class="fa fa-fire" aria-hidden="true"></i>Light Oil</a> ', 
            image:'pro_02.jpg',
            imageBelowTitle:'All PROTRON burners feature :',
            pdfFileLink:'MKT_211014_Protron_1.2_EN.pdf'
        },
        { 
            pageName: 'ek-evo', 
            mainHeading: 'EK EVO', 
            secondryHeading:'<h4>Monoblock burners from 250 kW to 13000 kW in gas, light oil and dual fuel</h4>', 
            upperContent: '<p style="padding:0px 10px 0px 0px;">- The design of the EK EVO is the result of a successful synergy between ELCO key features and essential design. Professionals will appreciate the flexibility of a 180° orientable air inlet, the compact switch box and the easy to clean glossy paint</p><p style="padding:5px 10px 0px 0px;">- All EK EVO models are fully electronically controlled and they are equipped with the exclusive ELCO man-machine interface allowing simple and effective programming operations</p>', 
            lowerContent: '<ul><li>integrated switch cabinet (ISC System) with modular concept complete for an easy installation with: BT300 control box, fan motor direct start-up, enumerated connection terminals, three-phase electrical power supply (available as options: power regulator, speed controller, O2/CO regulator and BUS interface)</li><li>innovative design allowing easy access to burner components for fast start-up and reduced maintenance time and space</li><li>orientable air intake box</li><li>products are in compliance with EN676 and EN267 European standards and with the following directives: 2014/35/EU Low Voltage Directive, 2014/30/UE EMC Directive, 2016/426/UE Gas Appliances Directive, 2006/42/EC Machinery Directive, 2011/65/EU RoHS2 Directive</li></ul>',
            sideBar:'<a class="btn-default" href="#"><i class="fa fa-fire" aria-hidden="true"></i>Natural Gas</a> <a class="btn-default" href="#"><i class="fa fa-fire" aria-hidden="true"></i>Dual Fuel</a> <a class="btn-default" href="#"><i class="fa fa-fire" aria-hidden="true"></i>Light Oil</a>', 
            image:'pro_03.jpg',
            imageBelowTitle:'All EK EVO burners feature:',
            pdfFileLink:'MKT_041017_EKEVO_1.7_EN.pdf'
        },
        { 
            pageName: 'nextron', 
            mainHeading: 'NEXTRON', 
            secondryHeading:'<h4>Monoblock burners from 250 kW to 11200 kW in gas, light oil and dual fuel</h4>', 
            upperContent: '<p style="padding:0px 10px 0px 0px;">- The original design of NEXTRON range is the result of a successful integration between burner and ELCO technologies</p><p style="padding:5px 10px 0px 0px;">- The NEXTRON burner range offers high acoustic comfort thanks to the Low Noise System. The patented air intake channel is carefully designed to achieve an acoustic level significantly lower than 80 dB(A) up to 10 MW</p>', 
            lowerContent: '<ul><li>Integrated switch cabinet (ISC System) with modular concept complete for an easy installation with: BT300 control box (MDE2 System and display), fan motor direct start-up, enumarated connection terminals (available as options: power regulator, speed controller, O2/CO regulator and BUS interface)</li><li>Innovative design allowing easy access to burner components for fast start-up and reduced maintenance time and space</li><li>Unique air intake channel design (patented) to reduce acoustic levels (Low Noise System)</li><li>Products are in compliance with EN676 and EN267 European standards and with the following directives: 2014/35/EU Low Voltage Directive, 2014/30/UE EMC Directive, 2016/426/UE Gas Appliances Directive, 2006/42/EC Machinery Directive, 2011/65/EU RoHS2 Directive</li></ul>', 
            sideBar:'<a class="btn-default" href="#"><i class="fa fa-fire" aria-hidden="true"></i>Natural Gas</a> <a class="btn-default" href="#"><i class="fa fa-fire" aria-hidden="true"></i>Dual Fuel</a> <a class="btn-default" href="#"><i class="fa fa-fire" aria-hidden="true"></i>Light Oil</a>', 
            image:'pro_04.jpg',
            imageBelowTitle:'All NEXTRON burners feature:',
            pdfFileLink:'MKT_180515_Nextron_1.5_EN.pdf'
        },
        { 
            pageName: 'n', 
            mainHeading: 'N', 
            secondryHeading:'<h4>Monoblock burners from 1300 kW to 22000 kW in gas, light oil and dual fuel</h4>', 
            upperContent: '<p style="padding:0px 10px 0px 0px;">- Thanks to innovative and weight-saving technologies, the N range is an optimal choice wherever compact solution and high power outputs are needed. The key feature is the physical division of the burner into a support frame and a separate air-routing housing</p><p style="padding:5px 10px 0px 0px;">- With the introduction of the new N11 burner model and the innovative solutions of this range, ELCO can now provide high performance and ease of use on its monoblock range up to 22 MW</p>', 
            lowerContent: '<ul><li>built-in or external control box, according to customer requirements</li><li>secured burner head adjustments during maintenance (RTC System)</li><li>closing of the air damper on burner shut-down</li><li>products are in compliance with EN676 and EN267 European standards and with the following directives: 2014/35/EU Low Voltage Directive, 2014/30/UE EMC Directive, 2016/426/UE Gas Appliances Directive, 2006/42/EC Machinery Directive, 2011/65/EU RoHS2 Directive</li></ul>', 
            sideBar:'<a class="btn-default" href="#"><i class="fa fa-fire" aria-hidden="true"></i>Natural Gas</a> <a class="btn-default" href="#"><i class="fa fa-fire" aria-hidden="true"></i>Dual Fuel</a> <a class="btn-default" href="#"><i class="fa fa-fire" aria-hidden="true"></i>Light Oil</a>', 
            image:'pro_05.jpg',
            imageBelowTitle:'All N burners feature:',
            pdfFileLink:''
        },
        { 
            pageName: 'HO-Tron', 
            mainHeading: 'HO-TRON', 
            secondryHeading:'<h4>Monoblock burners from 68 kW to 17000 kW in heavy oil</h4>', 
            upperContent: '<p style="padding:0px 10px 0px 0px;">- ELCO offers a wide range of heavy oil burners designed for traditional applications and industrial process applications</p><p style="padding:5px 10px 0px 0px;">- All burners feature easy access to the combustion component in order to simplify the maintenance operations</p><p style="padding:5px 10px 0px 0px;">- For a large extent of applications customised solutions can be offered in order to meet plants requirements</p>', 
            lowerContent: '<ul><li>pump mounted on the body up to model HO-TRON 6.8500 and separated motor-pump starting from model HO-TRON 6.10500</li><li>electrical heavy oil heater on board (oil supply to the burner at 80°C and 3 bar)</li><li>additional heaters on the pipes and the valves, into the pump and the nozzle holder</li><li>closing of the air flap on burner shut-down</li><li>complete control panel mounted on the burner with electrical equipment</li><li>products are in compliance with EN267 European standard and with the following directives: 2014/35/EU Low Voltage Directive, 2014/30/UE EMC Directive, 2006/42/EC Machinery Directive, 2011/65/EU RoHS2 Directive</li></ul>', 
            sideBar:'<a class="btn-default" href="#"><i class="fa fa-fire" aria-hidden="true"></i>Natural Gas</a> <a class="btn-default" href="#"><i class="fa fa-fire" aria-hidden="true"></i>Dual Fuel</a> <a class="btn-default" href="#"><i class="fa fa-fire" aria-hidden="true"></i>Light Oil</a>', 
            image:'pro_06.jpg',
            imageBelowTitle:'All HO-TRON burners feature:',
            pdfFileLink:''
        },
        { 
            pageName: 'gho-tron', 
            mainHeading: 'xFirst', 
            secondryHeading:'<h4>Monoblock burners from 414 kW to 17000 kW dual fuel (gas/heavy oil)</h4>', 
            upperContent: '<p style="padding:0px 10px 0px 0px;">- ELCO dual fuel GHO-TRON burners are suitable to work with natural gas or heavy oil up to 50°E at 50°C and are available in two stages and progressive version with electrical servomotor and double adjustable mechanical cam that allows air gas/heavy oil fine tuning</p><p style="padding:5px 10px 0px 0px;">- Configurated and special versions are available on request for selected type of applications and fuel characteristics</p>', 
            lowerContent: '<ul><li>separated motor-pump on board</li><li>electrical heavy oil heater (oil supply to the burner at 80°C and 3 bar)</li><li>additional heating on the pipes and the valves, into the pump and the nozzle holder</li><li>closing of the air flap on burner shut-down</li><li>complete electrical equipment in a switch cabinet mounted on the burner with control panel</li><li>products are in compliance with EN676 and EN267 European standards and with the following directives: 2016/426/EU Gas Appliances Directive, 2014/30/EU EMC Directive, 2014/35/EU Low Voltage Directive, 2006/42/EC Efficiency Directive</li></ul>', 
            sideBar:'<a class="btn-default" href="#"><i class="fa fa-fire" aria-hidden="true"></i>Natural Gas</a> <a class="btn-default" href="#"><i class="fa fa-fire" aria-hidden="true"></i>Dual Fuel</a> <a class="btn-default" href="#"><i class="fa fa-fire" aria-hidden="true"></i>Light Oil</a>', 
            image:'pro_07.jpg',
            imageBelowTitle:'All GHO-TRON burners feature:',
            pdfFileLink:'' 
        },
        { 
            pageName: 'd-tron',
            mainHeading: 'D-TRON', 
            secondryHeading:'<h4>Duoblock burners from 230 kW to 34000 kW in gas, light oil, heavy oil and dual fuel</h4>', 
            upperContent: '<p style="padding:0px 10px 0px 0px;">- Thanks to their extreme flexibility and ease of use D-TRON burners are suitable for all types of installation up to 34 MW</p><p style="padding:5px 10px 0px 0px;">- Burners can be assembled with air duct connection in different layouts in order to meet a wide range of specifications in terms of performance and overall dimensions</p><p style="padding:5px 10px 0px 0px;">- Versions suitable to work with pre-heated combustion air up to 200°C can be used in order to achieve greater values of efficiency</p>', 
            lowerContent: '<ul><li>electrical switch cabinet manufactured in different configurations assembled on the burner or delivered separately</li><li>control box for burner operation sequences</li><li>contactors and remote overload cut-out for control of the fan unit</li><li>fuse to protect the auxiliary equipment</li><li>switches, warning and information lights, manual power control</li><li>products are in compliance with EN676 and EN267 European standards and with the following directives: 2016/426/EU Gas Appliances Directive, 2014/30/EU EMC Directive, 2014/35/EU Low Voltage Directive, 2006/42/EC Efficiency Directive</li></ul>', 
            sideBar:'<a class="btn-default" href="#"><i class="fa fa-fire" aria-hidden="true"></i>Natural Gas</a> <a class="btn-default" href="#"><i class="fa fa-fire" aria-hidden="true"></i>Dual Fuel</a> <a class="btn-default" href="#"><i class="fa fa-fire" aria-hidden="true"></i>Light Oil</a><a class="btn-default" href="#"><i class="fa fa-fire" aria-hidden="true"></i>Heavy Oil</a><a class="btn-default" href="#"><i class="fa fa-fire" aria-hidden="true"></i>Dual Fuel</a>',
            image:'pro_10.jpg',
            imageBelowTitle:'All D-TRON burners feature:',
            pdfFileLink:'MKT_041017_D-TRON_1.8_EN.pdf'
        },
        { 
            pageName: 'ek-duo', 
            mainHeading: 'EK-DUO', 
            secondryHeading:'<h4>Duoblock burners from 600 kW to 16000 kW in gas, light oil and dual fuel</h4>', 
            upperContent: '<p style="padding:0px 10px 0px 0px;">- The EK-DUO models are high-performance burners offering well-engineered duoblock technology at an affordable price</p><p style="padding:5px 10px 0px 0px;">- These burners are predominantly used to burn standard fuels, i.e. domestic light oil and natural gas, and used in shell boilers, water tube boilers and thermal oil boilers.</p><p style="padding:5px 10px 0px 0px;">- The separate fan installation of the burner allows to overcome high combustion chamber resistance</p>', 
            lowerContent: '<ul><li>flame tube length according to installation requirements</li><li>secured burner head adjustments during maintenance</li><li>closing of the air damper on burner shutdown</li><li>built-in or external control box according to customer requirements</li><li>products are in compliance with EN676 and EN267 European standards and with the following directives: 2016/426/EU Gas Appliances Directive, 2014/30/EU EMC Directive, 2014/35/EU Low Voltage Directive, 2006/42/EC Efficiency Directive, 2011/65/EU RoHS2 Directive</li></ul>', 
            sideBar:'<a class="btn-default" href="#"><i class="fa fa-fire" aria-hidden="true"></i>Natural Gas</a> <a class="btn-default" href="#"><i class="fa fa-fire" aria-hidden="true"></i>Dual Fuel</a> <a class="btn-default" href="#"><i class="fa fa-fire" aria-hidden="true"></i>Light Oil</a>', 
            image:'pro_08.jpg',
            imageBelowTitle:'All EK-DUO burners feature:',
            pdfFileLink:'MKT_201217_DUOBLOCK80_EN.pdf' 
        },
        { 
            pageName: 'RPD', 
            mainHeading: 'RPD', 
            secondryHeading:'<h4>Duoblock burners from 500 kW to 80000 kW in gas, light oil, heavy oil and dual fuel</h4>', 
            upperContent: '<p style="padding:0px 10px 0px 0px;">- Thanks to their extreme flexibility, RPD burners are suitable for almost any firing-related task</p><p style="padding:5px 10px 0px 0px;">- All RPD models are fitted with adjustable air deflector plates, which can be used to swirl the combustion air flowing; the flame configuration can hereby be directly influenced according to the geometry of the combustion chamber, while the combustion head features optimized internal geometry to reduce head loss and the power demand of the fan motor</p>', 
            lowerContent: '<ul><li>air/gas ratio with Etamatic digital control box or with mechanical compound</li><li>adjustable flame length with air registers</li><li>flame tube length according to installation</li><li>closing of the air damper on burner shutdown</li><li>built-in or external control box according to customer requirements</li><li>products are in compliance with EN676 and EN267 European standards and with the following directives: 2016/426/EU Gas Appliances Directive, 2014/30/EU EMC Directive, 2014/35/EU Low Voltage Directive, 2006/42/EC Efficiency Directive, 2011/65/EU RoHS2 Directive</li></ul>', 
            sideBar:'<a class="btn-default" href="#"><i class="fa fa-fire" aria-hidden="true"></i>Natural Gas</a> <a class="btn-default" href="#"><i class="fa fa-fire" aria-hidden="true"></i>Dual Fuel</a> <a class="btn-default" href="#"><i class="fa fa-fire" aria-hidden="true"></i>Light Oil</a><a class="btn-default" href="#"><i class="fa fa-fire" aria-hidden="true"></i>Heavy Oil</a><a class="btn-default" href="#"><i class="fa fa-fire" aria-hidden="true"></i>Dual Fuel</a>',
            image:'pro_09.jpg',
            imageBelowTitle:'All RPD burners feature:',
            pdfFileLink:'MKT_201217_DUOBLOCK80_EN.pdf'
        }
    ];