import React, {Component} from 'react';

import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import {withRouter} from "react-router-dom"; // You will need these imports.
class IndustrialBurners extends Component{

	constructor(props) {
		super(props);
		this.state= {      
		//  comments: AppStore.getAll();
		};
	  }

	navigateTo = (pageName) => {
		var navigatePath = this.props.match.path + '/' +pageName;
		this.props.history.push(navigatePath);
	}


    componentDidMount() {
        document.body.style.background = '#ffffff';
    }


    render(){
		
        return(
            <div>

            <div className="header" id="home">
                
                <Header />        
                    
                    
            </div>
            
			<div className="container_fluid main_padd">
				<div className="container">
					
					<h3 className="main_heading" styles={{textTransform: 'uppercase'}}>MONOBLOCK</h3>
					<h5 className="heading_h5">11.0 KW / 22000.0 KW </h5>
					<div className="row products_box_02">
						 
						 <div className="col-md-3">
							<a href="javascript:void(0)" onClick={this.navigateTo.bind(this,'vectron')} >
								 <img src={process.env.PUBLIC_URL + "/assets/images/pro_01.jpg"} className="img-responsive" />
							 <h4>VECTRON</h4>
							</a>
						 </div>
						 
						 <div className="col-md-3">
							<a href="javascript:void(0)" onClick={this.navigateTo.bind(this,'protron')}>
								 <img src={process.env.PUBLIC_URL + "/assets/images/pro_02.jpg"} className="img-responsive" />
							 <h4>PROTRON</h4>
							</a>
						 </div>
						 
						 <div className="col-md-3">
							<a href="javascript:void(0)" onClick={this.navigateTo.bind(this,'ek-evo')}>
								 <img src={process.env.PUBLIC_URL + "/assets/images/pro_03.jpg"} className="img-responsive" />
							 <h4>EK EVO</h4>
							</a>
						 </div>
						 <div className="col-md-3">
							<a href="javascript:void(0)" onClick={this.navigateTo.bind(this,'nextron')}>
								 <img src={process.env.PUBLIC_URL + "/assets/images/pro_04.jpg"} className="img-responsive" />
							 <h4>NEXTRON</h4>
							</a>
						 </div>
						 
					</div>
					
					<div className="row products_box_02">
						 
						 <div className="col-md-3">
							<a href="javascript:void(0)" onClick={this.navigateTo.bind(this,'n')}>
								 <img src={process.env.PUBLIC_URL + "/assets/images/pro_05.jpg"} className="img-responsive" />
							 <h4>N</h4>
							</a>
						 </div>
						 
						 <div className="col-md-3">
							<a href="javascript:void(0)" onClick={this.navigateTo.bind(this,'HO-Tron')}>
								 <img src={process.env.PUBLIC_URL + "/assets/images/pro_06.jpg"} className="img-responsive" />
							 <h4>HO-TRON</h4>
							</a>
						 </div>
						 
						 <div className="col-md-3">
							<a href="javascript:void(0)" onClick={this.navigateTo.bind(this,'gho-tron')}>
								 <img src={process.env.PUBLIC_URL + "/assets/images/pro_07.jpg"} className="img-responsive" />
							 <h4>GHO-TRON</h4>
							</a>
						 </div>
						 <div className="col-md-3">
							
						 </div>
						 
					</div>
				</div>
			</div>
        
        
        
				<div className="container_fluid main_padd">
					<div className="container">
						
						<h3 className="main_heading" styles={{textTransform: 'uppercase'}}>DUOBLOCK</h3>
						<h5 className="heading_h5">230.0 KW / 80000.0 KW</h5>
						<div className="row products_box_02">
							 
							 <div className="col-md-3">
								<a href="javascript:void(0)" onClick={this.navigateTo.bind(this,'d-tron')}>
									 <img src={process.env.PUBLIC_URL +"/assets/images/pro_10.jpg"} className="img-responsive" />
								 <h4>D-TRON</h4>
								</a>
							 </div>
							 
							 <div className="col-md-3">
								<a href="javascript:void(0)" onClick={this.navigateTo.bind(this,'ek-duo')}>
									 <img src={process.env.PUBLIC_URL + "/assets/images/pro_08.jpg"} className="img-responsive" />
								 <h4>EK-DUO</h4>
								</a>
							 </div>
							 
							 <div className="col-md-3">
								<a href="javascript:void(0)" onClick={this.navigateTo.bind(this,'RPD')}>
									 <img src={process.env.PUBLIC_URL + "/assets/images/pro_09.jpg"} className="img-responsive" />
								 <h4>RPD</h4>
								</a>
							 </div>
							 <div className="col-md-3">
								
							 </div>
							 
						</div>
						
						
					</div>
				</div>
                
            <Footer />

            </div>
        )
    }

}


export default withRouter(IndustrialBurners);