import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom"; // You will need these imports.
import Header from '../../Layout/Header';
import Footer from '../../Layout/Footer';
import InnerPageBanner from '../../Layout/InnerPageBanner';
import Breadcrumb from '../../Layout/Breadcrumb';
import {jsonData} from './jsonData';

import ReactHtmlParser from "react-html-parser";

var pageName = '';
class File extends Component{

	constructor(props) {
		super(props);
		this.state= {      
			pageName: '', 
            mainHeading: '', 
            secondryHeading:'', 
            upperContent: '', 
            lowerContent: '', 
            sideBar:'', 
            image:'',
            imageBelowTitle:'',
            pdfFileLink:''
		};
	  }

    componentDidMount() {
        document.body.style.background = '#ffffff';
		console.log(this.props.match.params.id);
		pageName = this.props.match.params.id;

		const displayData = jsonData.find(el => el.pageName === pageName); // Possibly returns `undefined`
		console.log(displayData);

		this.setState({
			pageName: displayData.pageName, 
            mainHeading: displayData.mainHeading, 
            secondryHeading: displayData.secondryHeading, 
            upperContent: displayData.upperContent, 
            lowerContent: displayData.lowerContent, 
            sideBar: displayData.sideBar, 
            image: displayData.image,
            imageBelowTitle: displayData.imageBelowTitle,
            pdfFileLink: displayData.pdfFileLink
		});
    }


    render(){
		
        return(
            <div>

            <div className="header" id="home">
                
                <Header />        
                    
                    
            </div>
            <Breadcrumb Breadcrumb={this.state.pageName} />

			
				<div class="container_fluid main_padd">
					<div class="container">
			
						<h3 class="main_heading">{this.state.mainHeading}</h3>
						<div class="row products_box">
							<div class="col-md-4"> <img src={process.env.PUBLIC_URL + "/assets/images/"+this.state.image} class="img-responsive" /> </div>
							<div class="col-md-6">
								
								{ReactHtmlParser(this.state.secondryHeading)}
								{ReactHtmlParser(this.state.upperContent)}
								
							</div>
							<div class="col-md-2 pro_sidebar"> 
							{ReactHtmlParser(this.state.sideBar)}
							</div>
						</div>
					</div>
				</div>
				<section class="container_fluid second_cont">
					<div class="container">
						<div class="row">
							<div class="col-md-4">
								<h4>{ReactHtmlParser(this.state.imageBelowTitle)}</h4> 
							</div>
							
							<div class="col-md-8">
								
								{ReactHtmlParser(this.state.lowerContent)}
							
								<div class="wrapper">
									<a href={process.env.PUBLIC_URL + "/assets/pdf/"+this.state.pdfFileLink} target="_blank"><button type="button" class=" btn--border btn-read btn--animated">More Details</button></a>
								</div>
							
							</div>
						</div>
					</div>
				</section>	
			
            <Footer />

            </div>
        )
    }

}


export default withRouter(File);