import React, {Component} from 'react';

class Breadcrumb extends Component{

    render(){
        return(
            <div className="services-breadcrumb">
                <div className="inner_breadcrumb">

                    <ul className="short">
                        <li><a href="/">Home</a><span>|</span></li>
                        <li>{this.props.Breadcrumb}</li>
                    </ul>
                </div>
            </div>
        )
    }

}

export default Breadcrumb;