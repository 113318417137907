import React, {Component} from 'react';

class InnerPageBanner extends Component{

    render(){
        return(
            <div className="inner_banner"></div>
        )
    }

}

export default InnerPageBanner;