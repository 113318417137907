import React, {Component} from 'react';

import Header from '../../Layout/Header';
import Footer from '../../Layout/Footer';
import InnerPageBanner from '../../Layout/InnerPageBanner';
import Breadcrumb from '../../Layout/Breadcrumb';

class CommercialDocumentation extends Component{

    componentDidMount() {
        document.body.style.background = '#ffffff';
    }


    render(){
        return(
            <div>

            <div className="header" id="home">
                
                <Header />        
                    
                    
            </div>


        <div className="container_fluid main_padd">
            <div className="container">

                <h3 className="main_heading" style={{textTransform: 'uppercase'}}>Commercial Documentation </h3>
                <h5 className="heading_h5"></h5>
                <div className="row products_box_02">
                     
                     <div className="col-md-4">
                        <a href={process.env.PUBLIC_URL + "/assets/pdf/MKT210319_ELCO_Product_Range_2019_EN(1).pdf"} target="_blank" >
                             
                              <p style={{textAlign: 'left'}}>2019 Product Range</p>
                              <br />
                             <img src={process.env.PUBLIC_URL + "/assets/images/pdimg01.jpg"} className="img-responsive" style={{margin:'0px'}} />
                             
                       
                        </a>
                     </div>
                     
                       <div className="col-md-4">
                        <a href={process.env.PUBLIC_URL + "/assets/pdf/MKT260219_ELCO_brochure_FGR_(ENG)_1.2.pdf"} target="_blank">
                             
                              <p style={{textAlign: 'left',}}>


	FGR System and Low NOx Solutions</p>
                              <br />
                             <img src={process.env.PUBLIC_URL + "/assets/images/pdimg02.jpg"} className="img-responsive" style={{margin:'0px'}} />
                             
                       
                        </a>
                     </div>
                       <div className="col-md-4">
                        <a href={process.env.PUBLIC_URL + "/assets/pdf/MKT220219_BCE(ELCO)_brochure_EN.pdf"} target="_blank">
                            
                              <p style={{textAlign: 'left'}}>Special Industrial Burners and Combustion Equipment</p>
                              <br />
                             <img src={process.env.PUBLIC_URL + "/assets/images/pdimg03.jpg"} className="img-responsive" style={{margin:'0px'}} />
                             
                       
                        </a>
                     </div>
                     
                </div>
                
       
            </div>
                   <div className="container">
                
               
                <div className="row products_box_02">
                     
                     <div className="col-md-4">
                        <a href={process.env.PUBLIC_URL + "/assets/pdf/MKT_230117_Vectron_EN_1.9.pdf"} target="_blank">
                             
                              <h4 style={{textAlign: 'left'}}>VECTRON</h4>
                              <br />
                             <img src={process.env.PUBLIC_URL + "/assets/images/pdimg04.jpg"} className="img-responsive" style={{margin:'0px'}} />
                             
                       
                        </a>
                     </div>
                     
                       <div className="col-md-4">
                        <a  href={process.env.PUBLIC_URL + "/assets/pdf/MKT_041017_EKEVO_1.7_EN.pdf"} target="_blank">
                             
                                 <h4 style={{textAlign: 'left'}}>


	EK EVO</h4>
                              <br />
                             <img src={process.env.PUBLIC_URL + "/assets/images/pdimg05.jpg"} className="img-responsive" style={{margin:'0px'}} />
                             
                       
                        </a>
                     </div>
                       <div className="col-md-4">
                        <a href={process.env.PUBLIC_URL + "/assets/pdf/MKT_180515_Nextron_1.5_EN.pdf"} target="_blank">
                            
                                <h4 style={{textAlign: 'left'}}>NEXTRON</h4>
                              <br />
                             <img src={process.env.PUBLIC_URL + "/assets/images/pdimg06.jpg"} className="img-responsive" style={{margin:'0px'}} />
                             
                       
                        </a>
                     </div>
                    
                     
                  
                     
                </div>
                
       
            </div>
            
             <div className="container">
            
               
                <div className="row products_box_02">
                     
                     <div className="col-md-4">
                        <a href={process.env.PUBLIC_URL + "/assets/pdf/MKT_211014_Protron_1.2_EN.pdf"} target="_blank">
                             
                               <h4 style={{textAlign: 'left'}}>PROTRON</h4>
                              <br />
                             <img src={process.env.PUBLIC_URL + "/assets/images/pdimg07.jpg"} className="img-responsive" style={{margin:'0px'}} />
                             
                       
                        </a>
                     </div>
                     
                       <div className="col-md-4">
                        <a href={process.env.PUBLIC_URL + "/assets/pdf/MKT_201217_DUOBLOCK80_EN.pdf"} target="_blank">
                             
                                <h4 style={{textAlign: 'left'}}>


	EK-DUO and  RPD</h4>
                              <br />
                             <img src={process.env.PUBLIC_URL + "/assets/images/pdimg08.jpg"} className="img-responsive" style={{margin:'0px'}} />
                             
                       
                        </a>
                     </div>
                       <div className="col-md-4">
                        <a href={process.env.PUBLIC_URL + "/assets/pdf/MKT_041017_D-TRON_1.8_EN.pdf"} target="_blank">
                            
                                 <h4 style={{textAlign: 'left'}}>D-TRON </h4>
                              <br />
                             <img src={process.env.PUBLIC_URL + "/assets/images/pdimg09.jpg"} className="img-responsive" style={{margin:'0px'}} />
                             
                       
                        </a>
                     </div>
            
                     
                </div>
                
       
            </div>
        </div>
            
				
            
                <Footer />

            </div>
        )
    }

}


export default CommercialDocumentation;