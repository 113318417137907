import React, {Component} from 'react';

import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import InnerPageBanner from '../Layout/InnerPageBanner';
import Breadcrumb from '../Layout/Breadcrumb';

class AboutUs extends Component{

    componentDidMount() {
        document.body.style.background = '#ffffff';
    }


    render(){
        return(
            <div>

            <div className="header" id="home">
                <Header />         
            </div>
                <div >
                    <div className="container">
                        <div className="row products_box_03new">
                            <div className="col-md-12">

                                <p className="products_box_03_p1">We <b className="b"> Euromax International</b> (An <b className="b"> ISO 9001:2015 </b>Certified Company) introduce ourselves as Official distributor of Elco, GmbH-Germany supply for Industrial Burners & Medenus Gas-Druckregeltechnik GmbH, Germany for Gas Pressure regulators and gas Train. </p>

                                <p>The moto of our company is to provide complete turnkey solutions in the field of fuel handling in terms of Reliability, Affordability and Efficiency. Our impetus will be total solutions with guaranteed ROI. We provide fuel handling system of Liquid and Gas with reliable products for Retrofits on existing system, as well as conversions oil into gas of existing firing equipments like boiler and burners.
                                </p>

                                <p>The company is headed by myself  <b className="b">Mr. Alok Dev</b> having a experience of nearly two decades in this field. I am ably supported by a team of well trained sales/service engineers and tools to provide right solutions to the customers for deciding the correct equipment and to take care & provide service to the equipment after the sales. </p>

                                <p>By working in partnership with EUROMAX, customers benefit from the leading specialist extensive range of services in both Fuel handling system and burner/boiler technology. These benefits range from competent project management reaching out worldwide down to the close proximity of our customers to globally established service partners.
                                </p>
        
                            </div>
                            
                        </div>
                    </div>
                </div>
                <section className="container_fluid main_about">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                
                                <h5>Service and Solutions of the Euromax </h5>
                                        <ul>
                                            <li>Enquiry</li>
                                            <li>Engineering</li>
                                            <li>Planning</li>
                                            <li>Delivery</li>
                                            <li>Installation</li>
                                            <li>Start-Up (Commissioning)</li>
                                            <li>Plant Observation</li>
                                            <li>After Sales Service</li>
                                            <li>24/7 Word wide Service, on 365 days</li>
                                        </ul>

                                        <h5>Our Motto
                                        </h5>

                                        <p>Clean, lowest emissions, Optimum efficiency, reliable Operation and Proper Engineering, to customized demands. Medenusas desigened Gas Pressure regulator and Apexs designed liquid fuel handling systems fulfill all the operators requirements.
                                        </p>
                                        <ul>
                                            <li>International, competent You can count on this service
                                            </li>
                                            <li>We assist both competently and quickly - wherever you need us!
                                            </li>
                                        </ul>

                                        {/* <h5>Product Range
                                        </h5>

                                        <ul>
                                            <li>Gas Pressure Regulator
                                            </li>
                                            <li>Oil & Gas Burners
                                            </li>
                                            <li>Burner parts and Accessories
                                            </li>
                                        </ul> */}
                                
                            </div>
                            <div className="col-md-6">
                                
                                <div className="col-md-6 b_left">
                                            <img src={process.env.PUBLIC_URL + "/assets/images/ab.jpg"} alt=" " className="img-responsive" />

                                        </div>
                                        <div className="col-md-6 b_right">
                                            <img src={process.env.PUBLIC_URL + "/assets/images/ab1.jpg"} alt=" " className="img-responsive" />

                                        </div>
                                </div>
                            </div>
                        </div>
                    </section>    
                    
                    
            
                <Footer />

            </div>
        )
    }

}


export default AboutUs;